.p-privacy {
	
	.b-head {
        background: url("/about/privacy_policy/images/head_privacy_policy.jpg") no-repeat center / cover;
    }
    
    .s-sec02_box {
        background: rgba(255, 255, 255, .9);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        margin-top: 120px;
        
        @include mq() {
            padding: 20px;
            margin-top: 60px;
        }
        
        &:first-child {
            margin-top: 0;
        }
        
    }
	
    .s-sec02_box__inner {
        margin-top: 60px;
        padding-left: 25px;
		
		&:first-of-type{
			margin-top: 30px;
		}
        
        @include mq() {
			margin-top: 50px;
            padding-left: 0;
        }
        
    }
	
	.privacy_lead{
		font-weight: bold;
		font-size: 2rem;
        
        @include mq() {
            font-size: 1.8rem;
        }
	}
	
	.b-sttl03{
		margin-bottom: 30px;
		
		@include mq() {
            margin-bottom: 20px;
        }
	}
	
	.privacy_list01{
		margin-top: 10px;
		padding-left: 1em;
		font-size: 2rem;
		
		.privacy_list01{
			margin: 20px 0;
			padding-left: 2em;
		}
		
		@include mq() {
            font-size: 1.8rem;
			
			.privacy_list01{
				padding-left: 1em;
			}
        }
	}
	
	.s-sec02_text01 {
        font-size: 2rem;
		
		& + .s-sec02_text01{
			margin-top: 20px;
		}
        
        @include mq() {
            font-size: 1.8rem;
        }      
    }
}

.p-history {
	
	.b-head {
        background: url("/about/history/images/head_history.jpg") no-repeat center / cover;
    }
	
	.info{
		.info__label{
			text-align: right;
			padding-right: 50px;
			
			@include mq(870) {
				text-align: left;
				padding-right: 10px;
			}   
		}
		.info__rowspan2 {
            
			.info__row {

				&:nth-child(n+2){
					border-top: none;

					&::before {
						display: none;
					}

					.info__label {
						padding-top: 5px;
						padding-bottom: 5px;

					}
					.info__data {
						padding-top: 5px;
						padding-bottom: 5px;
					}				
				}
				&:last-child {
					.info__label {
						padding-bottom: 30px;
					}
					.info__data {
						padding-bottom: 30px;
					}

					@include mq(870) {
						.info__label {
							padding-bottom: 5px;

						}
						.info__data {
							padding-bottom: 20px;
						}
					}  
				}

			}
		}
	}
	
}