.p-top {
    
    .b-contents {
        background: url('/images/top_bg.jpg') no-repeat center top / 1920px;
        
        @include mq() {
            background: url('/images/top_bg_sp.jpg') no-repeat center top / 100%;
        }
    }
    
    .sec01 {
        padding: 220px 20px 0;
        color: #fff;
        position: relative;
        z-index: 1;
        
        @include mq() {
            padding-top: 90px;
        }
        
        .sec01__text01 {
            margin-top: 60px;
            
            @include mq() {
                margin-top: 10%;
            }
        }
        
        .sec01__img01 {
            text-align: center;
            margin-top: 40px;
            
            @include mq() {
                margin-top: 30px;
            }
            
            img {
                width: 14px;
            }
        }
        
        .sec01__sttl {
            //margin-top: 30px;
            margin-top: 160px;
            font-size: 5.5rem;
            line-height: 1.5;
            @extend %bold;
            text-align: center;
            text-shadow: 0px 0px 15px rgba(0, 0, 0, .25);
            
            @include mq() {
                //margin-top: 30px;
                margin-top: 50px;
                font-size: 2.4rem;
                line-height: 1.5;
            }
        }
        
        .sec01__lead {
            margin-top: 20px;
            text-align: center;
            font-size: 2.4rem;
            line-height: 2.65;
            @extend %bold;
            text-shadow: 0px 0px 15px rgba(0, 0, 0, .80);
            
            @include mq() {
                font-size: 1.7rem;
                line-height: 2;
                text-align: left;
                margin-top: 10px;
            }
        }
        
        .cards {
            margin-top: 110px;
            color: $black01;
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            @include mq() {
                //margin-top: 55px;
                display: block;
            }
            
            .cards__card {
                background: rgba(255, 255, 255, .9);
                width: 48.5%;
                padding: 35px;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);

                @include mq() {
                    width: 100%;
                    padding: 20px 20px 40px;
                }

                &:nth-child(n+3) {
                    margin-top: 3%;
                }

                &:nth-child(n+2) {

                    @include mq() {
                        margin-top: 30px;
                    }
                }
            }

            .cards__ico {
                width: 113px;
                height: 113px;
                margin: 0 auto;
                border-radius: 50%;
                @include gradient01;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 77.87%;
                }

                @include mq() {
                    width: 75px;
                    height: 75px;
                }
            }

            .cards__text_box {
                max-width: 380px;
                margin: 15px auto 0;
            }

            .cards__ttl {
                font-size: 3.3rem;
                line-height: 1.3;
                @extend %bold;
                text-align: center;
                color: $main;

                @include mq() {
                    font-size: 2.2rem;
                }
            }

            .cards__text {
                margin-top: 15px;
                line-height: 1.6;
                font-size: 2rem;

                @include mq() {
                    font-size: 1.8rem;
                }
            }

            .cards__links {
                display: -webkit-flex;
                display: flex;
                justify-content: center;

                @include mq(990) {
                    //display: block;
                }

                .cards__links__inner {
                    display: -webkit-flex;
                    display: flex;
                    flex-wrap: wrap;

                    @include mq(990) {
                        width: 190px;
                    }

                    @include mq() {
                        width: 160px;
                    }
                }
            }

            .cards__link {
                margin-top: 35px;

                @include mq() {
                    margin-top: 20px;
                }

                &:first-child {
                    margin-right: 30px;

                    @include mq(990) {
                        margin-right: 0;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    position: relative;
                    padding-left: 66px;
                    font-size: 2rem;
                    line-height: 1.3;
                    color: $main;

                    @include mq() {
                        font-size: 1.8rem;
                        padding-left: 46px;
                    }

                    @include hover {

                        .cards__link__ico {

                            &::before {
                                opacity: 1;
                            }
                        }

                        .cards__link__ico_arrow01 {

                            .svg_circle01 {
                                opacity: 1;
                            }

                            .svg_ling01 {
                                opacity: 0;
                            }

                            .svg_arrow01 {
                                opacity: 0;
                            }
                        }

                        .cards__link__text {

                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }

                &.cards__link--yellow {

                    a {
                        color: $yellow01;
                    }

                    .cards__link__ico_arrow01 {

                        .svg_circle01 {
                            fill: url(#svg_gradient02);
                        }

                        .svg_ling01 {
                            fill: url(#svg_gradient02);
                        }

                        .svg_arrow01 {
                            fill: url(#svg_gradient02_s);
                        }
                    }

                    .cards__link__text {

                        &::after {
                            background: $yellow01;
                        }
                    }
                }
            }

            .cards__link__ico {
                width: 46px;
                height: 46px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                //@include gradient01_h;
                display: -webkit-flex;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mq() {
                    width: 30px;
                    height: 30px;
                }
            }

            .cards__link__ico_arrow01 {
                width: 46px;
                height: 46px;
                z-index: 1;

                .svg_circle01 {
                    width: 100%;
                    height: 100%;
                    fill: url(#svg_gradient01);
                    opacity: 0;
                    transition: opacity .3s;
                }

                .svg_ling01 {
                    width: 100%;
                    height: 100%;
                    fill: url(#svg_gradient01);
                    transition: opacity .3s;
                }

                .svg_arrow01 {
                    width: 100%;
                    height: 100%;
                    fill: url(#svg_gradient01_s);
                    transition: opacity .3s;
                }
            }

            .cards__link__text {
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    background: $main;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: width .3s;
                }
            }
        }
        
    }
    
    .sec02 {
        margin-top: 90px;
        text-align: center;
        color: #fff;
        padding: 200px 20px 185px;
        position: relative;
        
        @include mq() {
            margin-top: 25px;
            padding: 55px 20px 50px;
        }
        
        .sec02__bg {
            width: 130%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(20deg);
            overflow: hidden;
            
            @include mq(1120) {
                width: 1480px;
            }
            
            @include mq() {
                width: 970px;
            }
            
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 75vw;
                background: url('/images/sec03_bg.jpg') no-repeat center top / cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-20deg);
                
                @include mq(1980) {
                    height: 1476px;
                }
                
                @include mq(1120) {
                    height: 1356px;
                }
                
                @include mq() {
                    height: 786px;
                }
            }
        }
        
        .b-contents__inner {
            position: relative;
            z-index: 1;
        }
    }
    
    .sec03 {
        margin-top: 185px;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        
        @include mq() {
            margin-top: 60px;
        }
        
        .b-contents__inner {
            max-width: 1100px;
            margin: 0 auto;
        }
        
        .sec03__2col {
            display: -webkit-flex;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
                flex-wrap: wrap;
            }
        }
        
        .sec03__box_l {
            width: 38.72%;
            
            @include mq() {
                width: 100%;
                order: 2;
                margin-top: 20px;
            }
        }
        
        .sec03__box_r {
            width: 52.72%;
            
            @include mq() {
                width: 100%;
                order: 1;
            }
        }
        
        &.section {
            
            .section__lead01 {
                margin-top: 0;
            }
            
            .section__link01 {
                margin-top: 50px;
                
                @include mq() {
                    margin-top: 20px;
                }
            }
        }
    }
    
    .section {
        
        .section__sttl01 {
            font-size: 5.5rem;
            line-height: 1.3;
            font-weight: normal;
            
            @include mq() {
                font-size: 3.2rem;
            }
        }
        
        .section__lead01 {
            margin-top: 20px;
            font-size: 2rem;
            line-height: 1.75;
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
        
        .section__figure01 {
            
            img {
                
                @include mq() {
                    width: 100%;
                }
            }
        }
        
        .section__link01 {
            margin-top: 65px;
            display: inline-block;
            
            @include mq() {
                margin-top: 20px;
            }
            
            &:first-child {
                margin-right: 30px;
                
                @include mq(990) {
                    margin-right: 0;
                }
            }
            
            &:last-child {
                margin-right: 0;
            }
            
            a {
                display: block;
                position: relative;
                padding-left: 75px;
                font-size: 2rem;
                line-height: 1.3;
                color: $main;
                
                @include mq() {
                    font-size: 1.8rem;
                    padding-left: 46px;
                }
                
                @include hover {
                    
                    .section__link01__ico {

                        &::before {
                            opacity: 1;
                        }
                    }

                    .section__link01__ico_arrow01 {

                        .svg_circle01 {
                            opacity: 1;
                        }

                        .svg_ling01 {
                            opacity: 0;
                        }

                        .svg_arrow01 {
                            opacity: 0;
                        }
                    }
                    
                    .section__link01__text {
                        
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
            
            &.section__link01--yellow {
                
                a {
                    color: $yellow01;
                }
                
                .section__link01__ico_arrow01 {

                    .svg_circle01 {
                        fill: url(#svg_gradient02);
                    }

                    .svg_ling01 {
                        fill: url(#svg_gradient02);
                    }

                    .svg_arrow01 {
                        fill: url(#svg_gradient02_s);
                    }
                }
                
                .section__link01__text {
                    
                    &::after {
                        background: $yellow01;
                    }
                }
            }
        }
        
        .section__link01__ico {
            width: 55px;
            height: 55px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            //@include gradient01_h;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @include mq() {
                width: 30px;
                height: 30px;
            }
        }
        
        .section__link01__ico_arrow01 {
            width: 55px;
            height: 55px;
            z-index: 1;

            .svg_circle01 {
                width: 100%;
                height: 100%;
                fill: url(#svg_gradient01);
                opacity: 0;
                transition: opacity .3s;
            }

            .svg_ling01 {
                width: 100%;
                height: 100%;
                fill: url(#svg_gradient01);
                transition: opacity .3s;
            }

            .svg_arrow01 {
                width: 100%;
                height: 100%;
                fill: url(#svg_gradient01_s);
                transition: opacity .3s;
            }
        }
        
        .section__link01__text {
            position: relative;
            font-size: 2.4rem;
            
            @include mq() {
                font-size: 2rem;
            }
            
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 2px;
                background: $main;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: width .3s;
            }
        }
    }
}