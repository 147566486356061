/************************************************

	基本

*************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video,
input, textarea {
    box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $gothic;
	line-height: 1.75;
	font-size:1.6em;
	color: #333;
    
    @include mq_ie {
        font-family: 
            "Meiryo",
            "Arial",
            sans-serif;
        line-height: inherit;
    }
}

button,
a{
    @include focus;
}

input {
    @include focus {
        box-shadow: 0px 0px 0px 1px #fff inset, 0px 0px 0px 3px #333 inset;
    }
    
}

ul {
    list-style: none;
}

a {
    
    &,
    &:link,
    &:active,
    &:visited,
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

button {
    border: none;
    border-radius: 0;
    background: none;
    font: inherit;
    padding: 0;
    cursor: pointer;
    text-transform: inherit;
}

img {
    max-width: 100%;
    vertical-align: top;
    height: auto;
}

/************************************************

	block

*************************************************/
.b-container {
    overflow: hidden;
}

.b-contents {
    margin-top: 133px;
    position: relative;
    z-index: 1;
    
    @include mq(1120) {
        margin-top: 90px;
    }
    
    @include mq() {
        margin-top: 56px;
    }
    
    .b-contents__inner {
        max-width: 1200px;
        margin: 0 auto;
    }
}

.b-unit_2col01 {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    
    > .b-unit_2col01__unit {
        width: 49%;
        
        &:nth-child(odd) {
            margin-right: 2%;
        }
        
        &:nth-child(n+3) {
            margin-top: 2%;
        }
    }
}

.b-unit_2col02 {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    
    > .b-unit_2col02__unit {
        width: 100%;
        
        &:nth-child(odd) {
            margin-right: 2%;
            
            @include mq() {
                margin-right: 0;
            }
        }
        
        &:nth-child(n+3) {
            margin-top: 2%;
        }
        
        &:nth-child(n+2) {
            
            @include mq() {
                margin-top: 2%;

            }
        }
    }
}

.b-head {
    padding: 48px 20px 198px;
    position: relative;
    
    @include mq(820) {
        padding: 28px 20px 103px;
    }
    
    @include mq() {
        padding: 28px 20px 73px;
    }
    
    &::after,
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 8px;
        position: absolute;
        left: 0;
        @include gradient_blue_h;
    }
    
    &::after {
        top: 0;
    }
    
    &::before {
        bottom: 0;
    }
    
    .b-head__breadcrumb {
        font-size: 1.3rem;
        line-height: 1.5;
        color: #fff;
        max-width: 1200px;
        margin: 0 auto;
        
        ul {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            
            li {
                position: relative;
                margin-bottom: 10px;
                
                &:not(:last-child) {
                    padding-right: 29px;
                    margin-right: 20px;
                    
                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 5.5px 0 5.5px 9px;
                        border-color: transparent transparent transparent #fff;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                }
                
            }
        }
        
        a {
            @include hover_op;
        }
    }
    
    .b-head__inner {
        text-align: center;
    }
    
    .b-head__ttl {
        margin-top: 145px;
        display: inline-block;
        padding: 20px 90px;
        text-align: center;
        @extend %bold;
        font-size: 5.5rem;
        line-height: 1.3;
        color: #fff;
        border: 2px solid;
        
        @include mq(820) {
            margin-top: 70px;
            padding: 20px 60px;
            font-size: 4rem;
        }
        
        @include mq() {
            margin-top: 40px;
            font-size: 3rem;
            padding: 15px 20px;
        }
    }
}

.b-sec_under {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 150px;
    position: relative;
    
    @include mq(820) {
        margin-top: 75px;
    }
    
    @include mq() {
        margin-top: 50px;
    }
    
    &.b-contents_bg01 {
        position: relative;

        &::before {
            content: "";
            display: block;
            background: url("../images/bg_item_service_full.png") no-repeat center/contain;
            width: 739px;
            height: 436px;
            position: absolute;
            top: 50px;
            left: -28%;
            z-index: -1;
            
            @include mq(820) {
                width: floor(739px * 2 / 3);
                height: floor(436px * 2 / 3);
                top: 10px;
            }

            @include mq(820) {
                width: floor(739px * 1 / 3);
                height: floor(436px * 1 / 3);
            }
            
        }
    }
    
    
}

.b-sttl01 {
    color: $blue02;
    font-size: 4.8rem;
    line-height: 2.5;
    @extend %bold;
    text-align: center;
    letter-spacing: .07em;
    font-feature-settings: "palt";
    position: relative;
    
    @include mq(820) {
        font-size: 4rem;
    }
    
    @include mq() {
        font-size: 3.2rem;
        line-height: 2.2;
    }
    
    &::before {
        content: '';
        display: block;
        background: url('../images/bg_item_service.png') no-repeat center / contain;
        width: 739px;
        height: 436px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
        z-index: -1;
        
        @include mq(820) {
            transform: translateY(-45%);
            width: floor(739px * 2 / 3);
            height: floor(436px * 2 / 3);
        }
        
        @include mq(820) {
            top: 0;
            transform: translateY(-30%);
            width: floor(739px * 1 / 3);
            height: floor(436px * 1 / 3);
        }
    }
    
    .b-sttl01__inner {
        border-bottom: 2px solid #999899;
        padding-bottom: 20px;
        
        @include mq() {
            padding-bottom: 10px;
        }
    }
    
    &.b-sttl01--no_item {
        
        &::before {
            display: none;
        }
    }
}
.b-sttl02 {
    font-size: 4.8rem;
    line-height: 2.5;
    @extend %bold;
    text-align: center;
    letter-spacing: .07em;
    font-feature-settings: "palt";
    position: relative;
    
    @include mq(820) {
        font-size: 4rem;
    }
    
    @include mq() {
        font-size: 3.2rem;
        line-height: 2.2;
    }
    
    .b-sttl02__inner {
        border-bottom: 2px solid #999899;
        padding-bottom: 20px;
        
        @include mq() {
            padding-bottom: 10px;
        }
    }
}

.b-sttl03 {
    font-size: 3.3rem;
    line-height: 1.5;
    @extend %bold;
    padding-left: 20px;
    position: relative;
    
    @include mq() {
        font-size: 2.2rem;
        padding-left: 15px;
        line-height: 1.3;
    }
    
    &::before {
        content: '';
        display: block;
        width: 5px;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        @include gradient_blue_deg;
    }
}

.b-sttl04 {
    font-size: 2.4rem;
    line-height: 1.5;
    @extend %bold;
    padding-left: 20px;
    position: relative;
    
    @include mq() {
        font-size: 2rem;
        padding-left: 15px;
        line-height: 1.3;
    }
    
    &::before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
        top: .4em;
        left: 0;
        //transform: translateY(-50%);
        @include gradient_blue_deg;
        
        @include mq() {
            width: 10px;
            height: 10px;
        }
    }
}

.b-sttl05 {
    display: inline-block;
    font-size: 2.4rem;
    line-height: 1.5;
    @extend %bold;
    position: relative;
    border-bottom: 2px solid #b3b3b3;
    
    &::before {
        content: "";
        width: 22px;
        height: 2px;
        background: $blue02;
        position: absolute;
        left: 0;
        bottom: -2px;
    }
    
    @include mq() {
        font-size: 2rem;
    }
    
}

.b-under_lead {
    font-size: 2.2rem;
    line-height: 2.9;
    text-align: center;
    margin-top: 40px;
    
    @include mq() {
        margin-top: 30px;
        font-size: 1.8rem;
        line-height: 2;
        text-align: left;
    }
}

.b-add_item {
    position: relative;
    
    &::before {
        content: '';
        display: block;
        background: url('../images/bg_item_service_full.png') no-repeat center / contain;
        width: 691px;
        height: 436px;
        position: absolute;
        top: -50%;
        left: -40%;
        transform: translateY(-50%);
        z-index: -1;
        
        @include mq(820) {
            transform: translateY(-45%);
            width: floor(739px * 2 / 3);
            height: floor(436px * 2 / 3);
        }
        
        @include mq(820) {
            top: 0;
            transform: translateY(-30%);
            width: floor(739px * 1 / 3);
            height: floor(436px * 1 / 3);
        }
    }
}

.b-box01 {
    background: rgba(255, 255, 255, .9);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
    padding: 75px 100px;
    box-sizing: border-box;
    margin-top: 120px;
    
    @include mq() {
        padding: 20px;
        margin-top: 60px;
    }
}


.b-category_nav {
    margin-top: 120px;
    background: #fff;
    border: 1px solid #b3b3b3;
    padding: 60px 40px 50px;
    color: #666;

    @include mq() {
        margin-top: 60px;
        padding: 30px 20px 25px;
    }

    .b-category_nav__inner {
        max-width: 1020px;
        margin: 0 auto;
    }

    .b-category_nav__ttl {
        font-size: 3.3rem;
        line-height: 1.3;
        @extend %bold;
        position: relative;
        z-index: 1;

        @include mq() {
            font-size: 2rem;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #666;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        
        .b-category_nav__ttl__inner {
            background: #fff;
            position: relative;
            z-index: 1;
            padding-right: 20px;
        }
    }
    
    .b-category_nav__list {
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        
        li {
            margin-right: 30px;
        }
    }
    
    .b-category_nav__link {
        margin-top: 35px;
        display: inline-block;

        @include mq() {
            margin-top: 20px;
        }

        /*&:first-child {
            margin-right: 30px;

            @include mq(990) {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }*/
        
        a {
            display: block;
            position: relative;
            padding-left: 66px;
            font-size: 2rem;
            line-height: 1.3;
            color: $main;

            @include mq() {
                font-size: 1.8rem;
                padding-left: 46px;
            }

            @include hover {

                .b-category_nav__link__ico {

                    &::before {
                        opacity: 1;
                    }
                }

                .b-category_nav__link__ico_arrow01 {

                    .svg_circle01 {
                        opacity: 1;
                    }

                    .svg_ling01 {
                        opacity: 0;
                    }

                    .svg_arrow01 {
                        opacity: 0;
                    }
                }

                .b-category_nav__link__text {

                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        &.b-category_nav__link--yellow {

            a {
                color: $yellow01;
            }

            .b-category_nav__link__ico_arrow01 {

                .svg_circle01 {
                    fill: url(#svg_gradient02);
                }

                .svg_ling01 {
                    fill: url(#svg_gradient02);
                }

                .svg_arrow01 {
                    fill: url(#svg_gradient02_s);
                }
            }

            .b-category_nav__link__text {

                &::after {
                    background: $yellow01;
                }
            }
        }
    }
    
    .b-category_nav__link__ico {
        width: 46px;
        height: 46px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        //@include gradient01_h;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq() {
            width: 30px;
            height: 30px;
        }
    }

    .b-category_nav__link__ico_arrow01 {
        width: 46px;
        height: 46px;
        z-index: 1;

        .svg_circle01 {
            width: 100%;
            height: 100%;
            fill: url(#svg_gradient01);
            opacity: 0;
            transition: opacity .3s;
        }

        .svg_ling01 {
            width: 100%;
            height: 100%;
            fill: url(#svg_gradient01);
            transition: opacity .3s;
        }

        .svg_arrow01 {
            width: 100%;
            height: 100%;
            fill: url(#svg_gradient01_s);
            transition: opacity .3s;
        }
    }

    .b-category_nav__link__text {
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: $main;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: width .3s;
        }
    }
}


/************************************************

	form

*************************************************/
.b-form01 {
    font-size: 2rem;
    
    @include mq() {
        font-size: 1.8rem;
    }
    
    .b-form01__text01 {
        margin-top: 80px;
        text-align: center;
        font-size: 2.2rem;
        
        @include mq() {
            margin-top: 40px;
            font-size: 1.8rem;
            text-align: left;
        }
    }
    
    .b-form__text02 {
        margin-top: 40px;
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        
        @include mq() {
            margin-top: 20px;
            font-size: 1.8rem;
        }
    }
    
    .b-form__text03 {
        margin-top: 20px;
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        
        @include mq() {
            font-size: 1.8rem;
            text-align: left;
        }
    }
    
    .b-form01__box {
        border: 1px solid #b3b3b3;
        background: #fff;
        padding: 80px 40px;
        margin-top: 70px;
        
        @include mq($tablet) {
            padding: 40px 20px;
        }
        
        @include mq() {
            margin-top: 35px;
            padding: 30px 15px;
        }
    }
    
    .b-form01__inner {
        max-width: 1000px;
        margin: 0 auto;
        
        &.b-form01__inner--complete {
            
            .b-form01__text01 {
                margin-top: 0;
            }
        }
    }
    
    .b-form__contents {
        margin-top: 40px;
        border-bottom: 1px solid #ccc;
        
        @include mq() {
            margin-top: 20px;
        }
        
        &:last-of-type {
            //border: none;
        }
        
        + .b-sttl03 {
            margin-top: 60px;
            
            @include mq() {
                margin-top: 30px;
            }
        }
        
        &.b-form__contents--confirm {
            
            .b-form__row {
                
                dt {
                    padding-top: 0;
                }
            }
        }
    }
    
    .b-form__row {
        padding: 40px;
        display: -webkit-flex;
        display: flex;
        
        @include mq($tablet) {
            padding: 40px 20px;
        }
        
        @include mq() {
            padding: 15px;
            display: block;
        }
        
        &:nth-child(odd) {
            background: mix($main, #fff, 10%);
        }
        
        dt {
            width: 300px;
            flex-shrink: 0;
            margin-right: 30px;
            font-size: 2rem;
            line-height: 1.5;
            @extend %bold;
            padding-top: 9px;
            
            @include mq() {
                margin-right: 0;
                flex-shrink: inherit;
                width: 100%;
            }
        }
        
        dd {
            width: 100%;
            
            @include mq() {
                margin-top: 10px;
            }
        }
        
        &.b-form__row--tel {
            
            .b-m10 {
                
                @include mq() {
                    margin: 0 !important;
                }
            }
        }
    }
    
    .b-form__ico_required {
        display: inline-block;
        background: $red01;
        color: #fff;
        @extend %bold;
        font-size: 1.5rem;
        line-height: 1;
        padding: 7px;
        border-radius: 5px;
        transform: translateY(-.15em);
    }
    
    .b-form__sttl {
        font-size: 2rem;
        line-height: 1.5;
        @extend %bold;
        padding-left: 20px;
        position: relative;

        @include mq() {
            font-size: 1.8rem;
            padding-left: 15px;
            line-height: 1.3;
        }

        &::before {
            content: '';
            display: block;
            width: 5px;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include gradient_blue_deg;
        }
        
        + .b-form__list {
            margin-top: 10px;
        }
    }
    
    .b-form__sttl02 {
        font-size: 2rem;
        line-height: 1.5;
        //@extend %bold;
        margin-top: 15px;
        margin-bottom: 5px;
        
        &:first-child {
            margin-top: 0;
        }
    }
    
    .b-form__list {
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -5px;
        
        li {
            margin-bottom: 5px;
            margin-right: 20px;
        }
        
        + .b-form__sttl {
            margin-top: 20px;
        }
    }
    
    .b-form01__note {
        font-size: 1.6rem;
        //line-height: 1.75;
        margin-top: 20px;
        text-align: center;
        
        @include mq() {
            font-size: 1.4rem;
            text-align: left;
        }
    }
    
    .b-form__2col01 {
        display: -webkit-flex;
        display: flex;
        
        @include mq() {
            display: block;
        }
        
        .b-form__2col01__box_l {
            margin-right: 20px;
        }
    }
    
    .b-form__btn01 {
        margin-top: 0;
        text-align: left;
        
        @include mq() {
            margin-top: 10px;
        }
        
        a,
        button {
            font-size: 1.8rem;
            padding: 13px 25px;
        }
    }
    
    .b-form01__err_box {
        border: 2px solid $red01;
        background: mix($red01, #fff, 5%);
        padding: 20px;
        margin-top: 40px;
        
        @include mq() {
            margin-top: 20px;
            padding: 10px;
        }
        
        p {
            color: $red01;
            @extend %bold;
        }
    }
}


.b-step {
    margin-top: 70px;
    
    @include mq() {
        margin-top: 35px;
    }
    
    ol {
        list-style: none;
        display: -webkit-flex;
        display: flex;
        
        li {
            width: 100%;
            margin-right: 50px;
            text-align: center;
            font-size: 2.8rem;
            line-height: 1.3;
            @extend %bold;
            color: $main;
            border: 2px solid;
            padding: 15px;
            background: #fff;
            position: relative;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @include mq() {
                font-size: 1.4rem;
                padding: 10px;
                margin-right: 25px;
            }
            
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent $main;
                position: absolute;
                top: 50%;
                left: 100%;
                transform: translate(20px, -50%);
                
                @include mq() {
                    transform: translate(10px, -50%);
                }
            }
            
            &:last-child {
                margin-right: 0;
                
                &::after {
                    display: none;
                }
            }
            
            &.is-current {
                background: $main;
                color: #fff;
                border-color: $main;
                
                &::after {
                    border-color: transparent transparent transparent #d5d5d5;
                }
                
                & ~ li {
                    background: #d5d5d5;
                    border-color: #d5d5d5;
                    color: #fff;
                    
                    &::after {
                        border-color: transparent transparent transparent #d5d5d5;
                    }
                }
            }
        }
    }
}


.b-radio01 {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 25px;
    line-height: 1.2;
    
    @include focus-within;
    
    input {
        opacity: 0;
        appearance: none;
        position: absolute;
        
        &:checked {
            
            + .b-radio01__ico {
                border-color: $main;
                
                &::before {
                    opacity: 1;
                }
            }
        }
    }
    
    .b-radio01__ico {
        width: 20px;
        height: 20px;
        border: 2px solid #b3b3b3;
        background: #f2f2f2;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) translateY(-1px);
        
        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 3px;
            background: $main;
            opacity: 0;
        }
    }
}

.b-checkbox01 {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 25px;
    line-height: 1.2;
    
    @include focus-within;
    
    input {
        opacity: 0;
        appearance: none;
        position: absolute;
        
        &:checked {
            
            + .b-checkbox01__ico {
                border-color: $main;
                
                &::before {
                    opacity: 1;
                }
            }
        }
    }
    
    .b-checkbox01__ico {
        width: 20px;
        height: 20px;
        border: 2px solid #b3b3b3;
        background: #f2f2f2;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) translateY(-1px);
        
        &::before {
            content: '';
            display: block;
            width: 8px;
            height: 4px;
            position: absolute;
            top: 42.5%;
            left: 50%;
            border-bottom: 2px solid $main;
            border-left: 2px solid $main;
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 0;
        }
    }
}

.b-input_text,
.b-input_tel,
.b-input_age01,
.b-input_age02,
.b-input_post,
.b-select {
    width: 100%;
    border: 2px solid #b3b3b3;
    background: #f2f2f2;
    padding: 10px;
    font-size: 2rem;
    
    @include mq() {
        font-size: 1.8rem;
    }
    
    /*&:valid {
        border-color: #b3b3b3;
    }
    
    &:invalid {
        border-color: $red01;
    }*/
}

.b-select {
    width: auto;
}

.b-input_post {
    width: 9em;
}

.b-input_tel {
    width: 4em;
}

.b-input_age01 {
    width: 4em;
}

.b-input_age02 {
    width: 3em;
}

.b-textarea01 {
    width: 100%;
    font-family: $gothic;
    border: 2px solid #b3b3b3;
    background: #f2f2f2;
    padding: 10px;
    font-size: 2rem;
}

::placeholder {
    color: #aaa;
}


/************************************************

	bottom

*************************************************/
.b-btn01 {
    margin-top: 50px;
    text-align: center;

    @include mq() {
        margin-top: 25px;
    }

    .b-btn01__inner {
        position: relative;
        z-index: 1;
    }

    a,
    button {
        //max-width: 640px;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        border-radius: 100px;
        padding: 15px 40px;
        font-size: 2.4rem;
        line-height: 1.3;
        color: #fff;
        @extend %bold;
        @include gradient_blue_h;
        transition: color .3s, background .3s;
        position: relative;

        @include mq() {
            padding: 15px;
            font-size: 1.8rem;
        }

        @include hover {
            color: $main;

            &::before {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            display: block;
            background: #fff;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100px;
            opacity: 0;
            transition: opacity .3s;
        }
    }
    
    &.is-disabled {
        
        a,
        button {
            background: #bbb;
            color: #eee;
            pointer-events: none;
        }
    }
}
.b-btn02 {
    margin-top: 50px;
    text-align: center;

    @include mq() {
        margin-top: 25px;
    }

    .b-btn02__inner {
        position: relative;
        z-index: 1;
    }

    a,
    button {
        //max-width: 640px;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        border-radius: 100px;
        padding: 15px 40px;
        font-size: 2.4rem;
        line-height: 1.3;
        color: #fff;
        @extend %bold;
        background: #666;
        transition: color .3s, background .3s;
        position: relative;

        @include mq() {
            padding: 15px;
            font-size: 1.8rem;
        }

        @include hover {
            color: #666;

            &::before {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            display: block;
            background: #fff;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100px;
            opacity: 0;
            transition: opacity .3s;
        }
    }
    
    &.is-disabled {
        
        a,
        button {
            background: #bbb;
            color: #eee;
            pointer-events: none;
        }
    }
}

.b-btn03 {
	margin-top: 70px;
	text-align: center;

	@include mq() {
		margin-top: 35px;
	}

	.b-btn03__inner {
		position: relative;
		z-index: 1;
	}

	a {
		max-width: 640px;
		text-align: center;
		display: block;
		margin: 0 auto;
		border-radius: 100px;
		padding: 25px;
		font-size: 3.8rem;
		line-height: 1.3;
		color: #fff;
		@extend %bold;
		@include gradient_blue_h;
		transition: color .3s, background .3s;
		position: relative;

		@include mq() {
			padding: 15px;
			font-size: 2.2rem;
		}

		@include hover {
			color: $main;

			&::before {
				opacity: 1;
			}
		}

		&::before {
			content: '';
			display: block;
			background: #fff;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 100px;
			opacity: 0;
			transition: opacity .3s;
		}
	}
}

.b-btn_2col {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    .b-btn_2col__box_r {
        margin-right: 30px;
        
        @include mq() {
            margin-right: 0;
            order: 2;
        }
    }
    
    .b-btn_2col__box_l {
        @include mq() {
            order: 1;
        }
    }
    
    .b-btn_2col__box_r,
    .b-btn_2col__box_l {
        
        @include mq() {
            width: 100%;
        }
    }
}

a {
    
    &.b-link01 {
        text-decoration: underline;
        color: $blue02;
        @include hover_op;
    }
}


/************************************************

	icon

*************************************************/
.b-ico_mail {
    position: relative;
    padding-right: 30px;
    display: inline-block;
    
    &::before {
        content: '';
        display: block;
        background: url('../images/ico_mail.svg') no-repeat center / 25px 18px;
        width: 25px;
        height: 18px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateY(-1px);
    }
}

.svg_defs {
    width: 0;
    height: 0;
    visibility: hidden;
    position: fixed;
    bottom: 0;
    
    #mask_circle01 {
        fill: #fff;
    }
}


/************************************************

	utility

*************************************************/
.b-m10 {margin: 10px !important;}
.b-mb5 {margin-bottom: 5px !important;}
.b-mb10 {margin-bottom: 10px !important;}
.b-mb15 {margin-bottom: 15px !important;}
.b-mb20 {margin-bottom: 20px !important;}
.b-mb30 {margin-bottom: 30px !important;}
.b-mb40 {margin-bottom: 40px !important;}
.b-mb50 {margin-bottom: 50px !important;}
.b-mt5 {margin-top: 5px !important;}
.b-mt10 {margin-top: 10px !important;}
.b-mt15 {margin-top: 15px !important;}
.b-mt20 {margin-top: 20px !important;}
.b-mt30 {margin-top: 30px !important;}
.b-mt40 {margin-top: 40px !important;}
.b-mt50 {margin-top: 50px !important;}
.b-ml5 {margin-left: 5px !important;}
.b-ml10 {margin-left: 10px !important;}
.b-ml20 {margin-left: 20px !important;}
.b-ml30 {margin-left: 30px !important;}
.b-ml40 {margin-left: 40px !important;}
.b-mr5 {margin-right: 5px !important;}
.b-mr10 {margin-right: 10px !important;}
.b-mr15 {margin-right: 15px !important;}
.b-mr20 {margin-right: 20px !important;}
.b-mr30 {margin-right: 30px !important;}
.b-p10 {padding: 10px !important;}
.b-p20 {padding: 20px !important;}
.b-pt10 {padding-top: 10px !important;}
.b-pb10 {padding-bottom: 10px !important;}
.b-pl40 {padding-left: 40px !important;}

.b-d_ib {
    display: inline-block;
}

.b-pc_br {
    display: inline;
    
    @include mq() {
        display: none;
    }
}

.b-sp_br {
    display: none;
    
    @include mq() {
        display: inline;
    }
}

/************************************************

	text

*************************************************/

.b-center {
    text-align: center !important;
}

.b-left {
    text-align: left !important;
}

.b-right {
    text-align: right !important;
}

.b-small {
    font-size: .8em;
}

.b-big {
    font-size: 1.2em;
}

.b-bold {
    font-weight: bold;
}

.b-red {
    color: $red01;
}

.b-indent01 {
    text-indent: -1em;
    padding-left: 1em;
}
