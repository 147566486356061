.p-about {
    
    .b-head {
        background: url("/about/images/head_about.jpg") no-repeat center / cover;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .b-add_item {
        
        &::before {
            top: -32%;
            left: -30%;
            transform: none;
            
            @include mq() {
                top: -10%;
            }
        }
    }
    
    .index_nav {
        
        ul {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            @include mq() {
                display: block;
            }
            
            li {
                width: 48%;
                position: relative;
                
                @include hover {
                    
                    .index_nav__bg {
                        
                        &::after{
                            opacity: 0;
                        }
                    }
                }
                
                @include mq() {
                    width: 100%;
                }
                
                &:nth-child(n+3) {
                    margin-top: 4%;
                }
                
                &:nth-child(n+2) {
                    
                    @include mq() {
                        margin-top: 20px;
                    }
                }
                
                a {
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 100px 30px;
                    height: 100%;
                    text-align: center;
                    font-size: 3.3rem;
                    line-height: 1.3;
                    @extend %bold;
                    color: #fff;
                    z-index: 3;
                    position: relative;
                    transition: color .3s;
                    
                    @include mq() {
                        padding: 40px 15px;
                        font-size: 2.2rem;
                    }
                }
                
                &:nth-child(2) {
                    
                    a {
                        position: relative;
                        
                        &::before {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            border: 1px solid #fff;
                            transition: opacity .3s;
                        }
                        
                        @include hover {
                            
                            &::before {
                                border: 1px solid #ccc;
                            }
                        }
                    }
                }
            }
        }
        
        .index_nav__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;
            
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                @include gradient01;
                opacity: .9;
                transition: opacity .3s;
            }
            
            img {
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        
        .index_nav__cover {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            transform: translate(-50%, -50%);
            border: 2px solid #fff;
            z-index: 2;
            //overflow: hidden;
            transition: background .3s;
            
            @include mq() {
                width: calc(100% - 20px);
                height: calc(100% - 20px);
            }
            
            &::before,
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                position: absolute;
            }
            
            &::before {
                border-width: 54px 54px 0 0;
                border-color: #fff transparent transparent transparent;
                top: -1px;
                left: -1px;
                
                @include mq() {
                    border-width: 27px 27px 0 0;
                }
            }
            
            &::after {
                border-width: 0 0 54px 54px;
                border-color: transparent transparent #fff transparent;
                bottom: -1px;
                right: -1px;
                
                @include mq() {
                    border-width: 0 0 27px 27px;
                }
            }
        }
    }
    
    .s-sec02 {
        margin-top: 100px;
        
        @include mq() {
            margin-top: 50px;
        }
        
        .b-box01 {
            margin-top: 70px;
            
        @include mq() {
            margin-top: 35px;
        }
        }
    }
    
    .info {
        
        .info__row {
            display: -webkit-flex;
            display: flex;
            border-top: 1px solid #d5d5d5;
            position: relative;
            
            @include mq(870) {
                display: block;
            }
            
            &::before {
                content: '';
                display: block;
                width: 240px;
                height: 3px;
                @include gradient01_h;
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(-2px);
                
                @include mq() {
                    width: 50px;
                }
            }
        }
        
        > .info__row {
                        
            &:last-of-type {
                border-bottom: 1px solid #d5d5d5;
                
                &::after {
                    content: '';
                    display: block;
                    width: 240px;
                    height: 3px;
                    @include gradient01_h;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translateY(2px);
                    
                    @include mq() {
                        width: 50px;
                    }
                }
            }
        }
        
        .info__rowspan2 {
            
            .info__row {
                
                &:first-child {
                    
                    .info__label {
                        padding-bottom: 5px;
                    }
                    
                    .info__data {
                        padding-bottom: 5px;
                    }
                }
                
                &:last-child {
                    border-top: none;
                    
                    &::before {
                        display: none;
                    }
                    
                    .info__label {
                        padding-top: 5px;
                        
                    }
                    
                    .info__data {
                        padding-top: 5px;
                    }
                }
            }
        }
        
        .info__label {
            font-size: 2rem;
            line-height: 1.3;
            @extend %bold;
            text-align: center;
            padding: 30px 10px;
            width: 240px;
            flex-shrink: 0;
            position: relative;
            margin-right: 40px;
            
            @include mq(870) {
                font-size: 1.8rem;
                text-align: left;
                padding: 20px 10px 5px;
            }
        }
        
        .info__data {
            font-size: 2rem;
            line-height: 1.3;
            padding: 30px 10px;
            width: 100%;
            
            @include mq(870) {
                font-size: 1.8rem;
                padding: 5px 10px 20px;
            }
        }
        
        .info__list {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            
            li {
                
                &::after {
                    content: '、';
                }
                
                &:last-child {
                    
                    &::after {
                        display: none;
                    }
                }
            }
        }
        
        .info__table {
            display: -webkit-flex;
            display: flex;
			margin-top: 20px;
            
            @include mq(1180) {
                display: block;
            }
            
			&:first-child {
				margin-top: 0;
			}
			
            + .info__table {
                margin-top: 20px;
            }
            
			&.info__table__box01 {
				margin-top: 45px;
				
				@include mq() {
					margin-top: 35px;
				}
				
			}
			
			
            .info__table__label {
                margin-right: 20px;
                width: 160px;
                position: relative;
                padding-left: .8em;
                
                &::before {
                    content: '';
                    display: block;
                    width: .4em;
                    height: .4em;
                    background: #999;
                    border-radius: 50%;
                    position: absolute;
                    top: .4em;
                    left: 0;
                }
                
                .b-small {
                    font-feature-settings: "palt";
                }
            }
			
			
			.info__table__label02 {
                margin-right: 20px;
                width: 160px;
                position: relative;
                padding-left: 1.2em;
                
                &::before {
                    content: '';
                    display: block;
                    width: .9em;
                    height: .9em;
                    background: #999;
                    border-radius: 50%;
                    position: absolute;
                    top: .2em;
                    left: 0;
                }
                
                .b-small {
                    font-feature-settings: "palt";
                }
            }
            
            .info__table__data {
                //margin-left: auto;
                
                @include mq(1180) {
                    margin-left: .8em;
                    margin-top: 5px;
                }
            }
        }
		
		.info__table02 {
			margin-top: 15px;
			
			&:first-child {
				margin-top: 0;
			}
			
			.info__table02__label {
				@extend %bold;
			}
			
			.info__table02__list {
				margin-top: 5px;
				
				li {
					position: relative;
					padding-left: .8em;
					
					+ li {
						margin-top: 10px;
					}
					
					&::before {
						content: "";
						display: block;
						width: .4em;
						height: .4em;
						background: #999;
						border-radius: 50%;
						position: absolute;
						top: .4em;
						left: 0;
					}
				}
			}
			
		}
		
    }
    
    .b-category_nav {
        
        .b-category_nav__link {
            
            .b-category_nav__link__text {
                font-weight: normal;
            }
        }
    }
            
    .footer {
        margin-top: 75px;
        
        .footer__inner {
            //transform: translateX(75px);
            
            @include mq(1420) {
                transform: none;
            }
        }
    }
}


.p-about_mission {
    
    .b-head {
        background: url("/about/mission/images/head_mission.jpg") no-repeat center / cover;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .b-add_item {
        
        &::before {
            top: -32%;
            left: -30%;
            transform: none;
            
            @include mq() {
                top: -10%;
            }
        }
    }
    
    .b-sttl01 {
        
        .b-sttl01__inner {
            background: #fff;
        }
    }
    
    .sttl01 {
        font-size: 3.6rem;
        line-height: 1.8;
        //@extend %bold;
        text-align: center;
        margin-top: 70px;
        
        @include mq() {
            font-size: 2.6rem;
        }
    }
    
    .mission {
        //margin-left: 25px;
        margin-top: 50px;
        
        @include mq() {
            margin-top: 25px;
        }
        
        .mission__figure {
            border-top: 3px solid $main;
            border-bottom: 3px solid $main;
        }
        
        .mission__text {
            margin-top: -50px;
            background: #fff;
            position: relative;
            z-index: 1;
            border-top: 3px solid $main;
            margin-right: 50px;
            font-size: 2rem;
            padding: 20px 25px 0 0;
            
            @include mq() {
                margin-top: 0;
                margin-right: 0;
                font-size: 1.8rem;
                padding: 10px 0 0 0;
                border-top: none;
            }
        }
    }
}


.p-about_message {
    
    .p-about_message__ttl {
        text-align: center;
    }
    
    .b-head {
        background: url("/about/message/images/head_message.jpg") no-repeat center / cover;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .b-add_item {
        
        &::before {
            top: -32%;
            left: -30%;
            transform: none;
            
            @include mq() {
                top: -10%;
            }
        }
    }
    
    .message {
        margin-top: 60px;
        
        @include mq() {
            margin-top: 30px;
        }
        
        .message__ttl {
            font-size: 3.3rem;
            line-height: 1.5;
            @extend %bold;
            //padding-left: 20px;
            position: relative;
            color: $blue02;

            @include mq() {
                font-size: 2.2rem;
                //padding-left: 15px;
                line-height: 1.3;
            }

            /*&::before {
                content: '';
                display: block;
                width: 5px;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                @include gradient_blue_deg;
            }*/
        }
        
        .mission__text {
            margin-top: 20px;
            font-size: 2rem;
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
        
        .mission__company {
            margin-top: 20px;
            text-align: right;
            @extend %bold;
            
            @include mq() {
                font-size: 1.4rem;
            }
        }
        
        .mission__name {
            font-size: 1.8rem;
            text-align: right;
            @extend %bold;
            
            @include mq() {
                font-size: 1.6rem;
            }
        }
    }
}

