$gothic:
    "游ゴシック medium",
    "YuGothic medium",
    "Yu Gothic medium",
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    "BIZ UDPGothic",
    "Meiryo",
    //"Helvetica Neue",
    "Arial",
    sans-serif;

$gothic_bold:
    "游ゴシック",
    "YuGothic",
    "Yu Gothic",
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    "BIZ UDPGothic",
    "Meiryo",
    //"Helvetica Neue",
    "Arial",
    sans-serif;

$blue01: #0C99B5;
$blue02: #076baf;
$red01: #de2525;
$main: #11aeb6;
$black01: #191919;
$yellow01: #f1ab3e;

$tablet: 820;

@mixin hover() {
	@media (any-hover: hover) {
		&:hover {
			@content;
		}
	}
	
	@include mq_ie {
		&:hover {
			@content;
		}
	}
}

@mixin mq_ie() {
	@at-root _:-ms-input-placeholder, :root & {
		@content;
	}
}

@mixin mq($size:767, $minmax:max) {
	@media screen and (#{$minmax}-width: #{$size}px) {
		@content;
	}
}

@mixin focus() {
    @at-root .use-key &:focus {
        outline: none;
        //outline: -webkit-focus-ring-color auto 1px;
        box-shadow: 0px 0px 0px 2px #000, 0px 0px 0px 3px #fff;
        @content;
    }
}

@mixin focus-within() {
    @at-root .use-key &:focus-within {
        outline: none;
        //outline: -webkit-focus-ring-color auto 1px;
        box-shadow: 0px 0px 0px 2px #000, 0px 0px 0px 3px #fff;
        @content;
    }
}

@mixin hover_focus() {
    @include hover() {
        @content;
    }
    
    @include focus() {
        @content;
    }
}


@mixin hover_op($val:.7) {
    transition: opacity .3s;
    
    @include hover() {
        opacity: $val;
        @content;
    }
}

@mixin gradient_blue_h {
    background: rgb(17,174,182);
    background: -moz-linear-gradient(left,  rgba(17,174,182,1) 0%, rgba(8,140,180,1) 66%, rgba(0,106,179,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(17,174,182,1) 0%,rgba(8,140,180,1) 66%,rgba(0,106,179,1) 100%);
    background: linear-gradient(to right,  rgba(17,174,182,1) 0%,rgba(8,140,180,1) 66%,rgba(0,106,179,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11aeb6', endColorstr='#006ab3',GradientType=1 );
}

@mixin gradient_blue_deg($deg:-52) {
    background: rgb(17,174,182);
    background: -moz-linear-gradient(#{$deg}deg,  rgba(17,174,182,1) 0%, rgba(8,140,180,1) 66%, rgba(0,106,179,1) 100%);
    background: -webkit-linear-gradient(#{$deg}deg,  rgba(17,174,182,1) 0%,rgba(8,140,180,1) 66%,rgba(0,106,179,1) 100%);
    background: linear-gradient(180deg-$deg,  rgba(17,174,182,1) 0%,rgba(8,140,180,1) 66%,rgba(0,106,179,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11aeb6', endColorstr='#006ab3',GradientType=1 );
}

@mixin gradient01 {
    background: rgb(17,174,182);
    background: -moz-linear-gradient(-45deg,  rgba(17,174,182,1) 0%, rgba(76,181,124,1) 66%, rgba(135,189,66,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba(17,174,182,1) 0%,rgba(76,181,124,1) 66%,rgba(135,189,66,1) 100%);
    background: linear-gradient(135deg,  rgba(17,174,182,1) 0%,rgba(76,181,124,1) 66%,rgba(135,189,66,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11aeb6', endColorstr='#87bd42',GradientType=1 );
}

@mixin gradient01_h {
    background: rgb(17,174,182);
    background: -moz-linear-gradient(left,  rgba(17,174,182,1) 0%, rgba(76,181,124,1) 66%, rgba(135,189,66,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(17,174,182,1) 0%,rgba(76,181,124,1) 66%,rgba(135,189,66,1) 100%);
    background: linear-gradient(to right,  rgba(17,174,182,1) 0%,rgba(76,181,124,1) 66%,rgba(135,189,66,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11aeb6', endColorstr='#87bd42',GradientType=1 );
}

@mixin gradient01_h_bd {
    border-image: rgb(17,174,182);
    border-image: -moz-linear-gradient(left,  rgba(17,174,182,1) 0%, rgba(76,181,124,1) 66%, rgba(135,189,66,1) 100%);
    border-image: -webkit-linear-gradient(left,  rgba(17,174,182,1) 0%,rgba(76,181,124,1) 66%,rgba(135,189,66,1) 100%);
    border-image: linear-gradient(to right,  rgba(17,174,182,1) 0%,rgba(76,181,124,1) 66%,rgba(135,189,66,1) 100%);
}

@mixin gradient02_h {
    background: rgb(241,193,74);
    background: -moz-linear-gradient(left,  rgba(241,193,74,1) 0%, rgba(239,168,56,1) 66%, rgba(237,143,38,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(241,193,74,1) 0%,rgba(239,168,56,1) 66%,rgba(237,143,38,1) 100%);
    background: linear-gradient(to right,  rgba(241,193,74,1) 0%,rgba(239,168,56,1) 66%,rgba(237,143,38,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1c14a', endColorstr='#ed8f26',GradientType=1 );
}

%bold {
    font-family: $gothic_bold;
    font-weight: bold;
    
    @include mq_ie {
        font-family: 
            "Meiryo",
            "Arial",
            sans-serif;
    }
}




