.p-environmental-policy {
	
	.b-head {
        background: url("/environmental_policy/images/head_environmental-policy.jpg") no-repeat center / cover;
    }
	
	.b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
	
	.b-sttl01 { 
    	&::before {
			background: url("../images/bg_item_service_full.png") no-repeat center/contain;
		}
		
		.b-sttl01__inner{
			background: #fff;
		}
	}
	
	.b-under_lead {
		text-align: left;
		line-height: 2.3;
		margin-top: 20px;
	}
}
