.p-contact {
    
    .b-head {
        background: url("/contact/images/head_contact.jpg") no-repeat center / cover;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .tell_links {
        margin-top: 120px;
        background: #fff;
        border: 1px solid #b3b3b3;
        padding: 60px 40px 50px;
        color: #666;
        
        @include mq() {
            margin-top: 60px;
            padding: 30px 20px 25px;
        }
        
        .tell_links__inner {
            max-width: 1020px;
            margin: 0 auto;
        }
        
		.tell_links__box02 {
			margin-top: 40px;
			
			@include mq() {
				margin-top: 20px;
			}
			
		}
		
		.tell_links__box03 {
			display: -webkit-flex;
			display: flex;
			flex-wrap: wrap;
			//margin-top: 20px;
			
			@include mq() {
				display: block;
			}
			
			+ .tell_links__box03 {
				margin-top: 10px;
			}
			
		}
		
        .tell_links__ttl {
            font-size: 3.3rem;
            line-height: 1.3;
            @extend %bold;
            position: relative;
            z-index: 1;
            
            @include mq() {
                font-size: 2rem;
            }
            
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    
                }
            }
        }
        
        .tell_links__ico {
            background: #fff;
            position: relative;
            display: inline-block;
            padding-left: 80px;
            padding-right: 20px;
            
            @include mq() {
                padding-left: 40px;
                padding-right: 10px;
            }
            
            &::before {
                content: '';
                display: block;
                background: #666 url('../images/ico_tell.svg') no-repeat center / 28px 36px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    width: 30px;
                    height: 30px;
                    background-size: 14px 18px;
                }
            }
        }
        
        .tell_links__list {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            li {
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }
        }
        
        .tell_links__label {
            font-size: 2.3rem;
            line-height: 1.5;
            @extend %bold;
            width: 480px;
            flex-shrink: 0;
            margin-right: 100px;
            
            @include mq() {
                font-size: 1.6rem;
                margin-right: 0;
                flex-shrink: inherit;
				width: 100%;
            }
			
			&.tell_links__label--round {
				position: relative;
				padding-left: 1.2em;
				
				&::before {
					content: "";
					display: block;
					width: .9em;
					height: .9em;
					background: #999;
					border-radius: 50%;
					position: absolute;
					top: .2em;
					left: 0;
				}
				
				@include mq() {
					&::before {
						top: .25em;
					}
				}
				
			}
			
			&.tell_links__label--fixed {
				width: 613px;
			}
			
			&.tell_links__label--no_fixed {
				width: auto;
			}
			
        }
        
        .tell_links__number {
            font-size: 2.5rem;
            line-height: 1.3;
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
    }
    
    .b-sttl01 {
        margin-top: 80px;
        
        @include mq() {
            margin-top: 40px;
        }
    }
    
    .footer {
        margin-top: 75px;
    }
}