/************************************************

	採用情報

*************************************************/
.p-recruit {
    .b-head {
        background: url("/recruit/images/head_recruit.jpg") no-repeat center / cover;
    }
    
    .b-contents_bg01 {
        padding-top: 133px;
        overflow: hidden;
        background: url("/recruit/images/img_bg01.jpg") no-repeat center / cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        
        @include mq(1120) {
            padding-top: 90px;
        }
        
        @include mq() {
            padding-top: 56px;
        }
        
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .b-sttl01 {
        &::before {
            background: url("../images/bg_item_service_full.png") no-repeat center / contain;
        }
        
        .b-sttl01__inner {
            background: #fff;
        }
    }
    
    .index_nav {
        margin-top: 100px;
        
        @include mq() {
            margin-top: 50px;
        }
        
        
        ul {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            @include mq() {
                display: block;
            }
            
            li {
                width: 48%;
                position: relative;
                
                @include hover {
                    
                    .index_nav__bg {
                        &::after {
                            opacity: 0;
                        }
                    }
                }
                
                @include mq() {
                    width: 100%;
                }
                
                &:nth-child(n+2) {
                    @include mq() {
                        margin-top: 20px;
                    }
                }
                
                &:nth-child(n+3) {
                    margin-top: 4%;
                }
                
                a {
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 100px 30px;
                    height: 100%;
                    text-align: center;
                    font-size: 3.3rem;
                    @extend %bold;
                    line-height: 1.3;
                    color: #fff;
                    z-index: 3;
                    position: relative;
                    transition: color .3s;
                    
                    @include mq() {
                        padding: 40px 15px;
                        font-size: 2.2rem;
                    }
                    
                }
                
            }
        }
        
        .index_nav__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;
            
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                @include gradient01;
                opacity: .9;
                transition: opacity .3s;
            }
            
            img {
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            
        }
        
        .index_nav__cover {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            transform: translate(-50%, -50%);
            border: 2px solid #fff;
            z-index: 2;
            transition: background .3s;
            
            @include mq() {
                width: calc(100% - 20px);
                height: calc(100% - 20px);
            }
            
            &::before, &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                position: absolute;
            }
            
            &::before {
                border-width: 54px 54px 0 0;
                border-color: #fff transparent transparent transparent;
                top: -1px;
                left: -1px;
                
                @include mq() {
                    border-width: 27px 27px 0 0;
                }
                
            }
            
            &::after {
                border-width: 0 0 54px 54px;
                border-color: transparent transparent #fff transparent;
                bottom: -1px;
                right: -1px;
                
                @include mq() {
                    border-width: 0 0 27px 27px;
                }
                
            }
            
        }
        
    }
    
    .b-category_nav {
        margin-top: 40px;
        
        @include mq() {
            margin-top: 30px;
        }
        
    }
    
    .s-sec01_box {
        //background: rgba(255, 255, 255, .9);
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        
        @include mq() {
            padding: 20px;
        }
        
    }
    
    .s-sec03 {
        margin-top: 120px;
        
        @include mq() {
            margin-top: 60px;
        }
    }
    
    .recruit_title01 {
        color: $blue02;
        font-size: 4.8rem;
        line-height: 2.5;
        @extend %bold;
        text-align: center;
        letter-spacing: .07em;
        font-feature-settings: "palt";
        position: relative;
        
        @include mq(820) {
            font-size: 4rem;
        }
        
        @include mq() {
            font-size: 3.2rem;
            line-height: 2.2;
        }
        
        .recruit_title01__inner {
            border-bottom: 2px solid #999899;
            padding-bottom: 20px;

            @include mq() {
                padding-bottom: 10px;
            }
        }
    }
    
    .recruit_title02 {
        font-size: 4rem;
        @extend %bold;
        color: $blue02;
        line-height: 1.3;
        position: relative;
        z-index: 1;
        
        @include mq() {
            font-size: 2.5rem;
        }
        
        .recruit_title02__inner {
            background: #fff;
            position: relative;
            display: inline-block;
            padding-left: 105px;
            
            @include mq() {
                padding-left: 53px;
            }
            
            &::before {
                content: "";
                display: block;
                background: $blue02 url("/recruit/driver/images/img_ico01.svg") no-repeat center / 54px 51px;
                width: 85px;
                height: 85px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    background-size: 29px 26px;
                    width: 43px;
                    height: 43px;
                }
                
            }
        }
    }
    
    .recruit_info {
        margin-top: 60px;
        
        @include mq() {
            margin-top: 30px;
        }
        
        .recruit_info__row {
            display: -webkit-flex;
            display: flex;
            position: relative;
            
            @include mq() {
                display: block;
                margin-bottom: 5px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
            }
            
            dt, dd {
                padding: 25px 15px;
                font-size: 2rem;
                line-height: 1.3;
                
                @include mq() {
                    //padding: 15px 10px;
                    padding: 10px;
                    font-size: 1.8rem;
                }
                
            }
                        
            &:last-child {
                .recruit_info__label {
                    border-bottom: none;
                }
                
                .recruit_info__date {
                    border-bottom: 1px solid #888;
                    
                    @include mq() {
                        border-bottom: none;
                    }
                    
                }
            }
            
        }
        
        .recruit_info__label {
            @extend %bold;
            color: #fff;
            border-bottom: 1px solid #fff;
            background: #888;
            flex-basis: 260px;
            flex-shrink: 0;
            
            @include mq() {
                flex-basis: auto;
                border-bottom: none;
            }
            
        }
        
        .recruit_info__date {
            width: 100%;
            border: 1px solid #888;
            border-left: none;
            border-bottom: none;
            
            @include mq() {
                border: none;
            }
            
        }
        
        .recruit_info_list01 {
            li {
                text-indent: -1em;
                padding-left: 1em;
                line-height: 1.3;
                margin-top: 10px;
                
                &:first-child {
                    margin-top: 0;
                }
                
                @include mq() {
                    margin-top: 5px;
                }
                
            }
        }
        
        .recruit_info_text01 {
            margin-top: 15px;
            
            &:first-child {
                margin-top: 0;
            }
            
            h3 {
                font-weight: normal;
                margin-bottom: 5px;
            }
            
            .recruit_info_text01__row01 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 3em;
                    flex-shrink: 0;
                }
            }
            
            .recruit_info_text01__row02 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 2em;
                    flex-shrink: 0;
                }
            }
            
            .recruit_info_text01__row03 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 1em;
                    flex-shrink: 0;
                }
            }
            
            .recruit_info_text01__row04 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 4em;
                    flex-shrink: 0;
                }
            }
            
            .recruit_info_text01__row05 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 5em;
                    flex-shrink: 0;
                }
            }
            
            .recruit_info_text01__row06 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 6em;
                    flex-shrink: 0;
                }
            }
            
            .recruit_info_text01__row07 {
                display: -webkit-flex;
                display: flex;
                
                dt, dd {
                    padding: 0;
                }
                
                dt {
                    margin-right: 1em;
                    flex-basis: 7em;
                    flex-shrink: 0;
                }
            }
            
        }
        
        .recruit_info_text02 {
            margin-top: 5px;
            
            &:first-child {
                margin-top: 0;
            }
            
        }
        
    }
    
    .recruit_entry {
        margin-top: 100px;
        display: flex;
        justify-content: center;
        
        @include mq() {
            margin-top: 20px;
            display: block;
        }
        
    }
    
    .recruit_entry__btn {
        
        a {
            display: block;
            background: $red01;
            border: 2px solid $red01;
            border-radius: 100px;
            color: #fff;
            text-align: center;
            font-size: 3rem;
            @extend %bold;
            line-height: 1.3;
            min-width: 515px;
            padding: 20px;
            transition: color .3s, background .3s;
            
            @include hover {
                background: #fff;
                color: $red01;
            }
            
            @include mq() {
                font-size: 2rem;
                min-width: inherit;
                padding: 15px;
                width: 100%;
            }
            
        }
        
    }
    
    
}


/************************************************

	採用情報（詳細）

*************************************************/
.p-recruit_office-work, .p-recruit_driver, .p-recruit_salesman, .p-recruit_operator {
    position: relative;
    
    .b-head {
        background: none;
        padding-bottom: 115px;
        
        @include mq() {
            padding-bottom: 58px;
        }
        
        &::before {
            content: none;
        }
        
        .b-head__ttl {
            margin-top: 110px;
            padding: 0;
            padding-bottom: 40px;
            border: none;
            position: relative;
            
            @include mq() {
                margin-top: 55px;
                padding-bottom: 20px;
            }
            
            &::before {
                content: "";
                display: block;
                width: 150px;
                height: 3px;
                background: #fff;
                position: absolute;
                left: calc(50% - 75px);
                bottom: 0;
                
                @include mq() {
                    width: 75px;
                    left: calc(50% - 38px);
                }
            }
        }
        
    }
    
    .b-sec_under {
        margin-top: 0;
    }
    
    footer {
        .footer__bg {
            mix-blend-mode: initial;
        }
    }
}


/************************************************

	採用情報（事務）

*************************************************/
.p-recruit_office-work {
    /*.b-contents_bg01 {
        background: url("/recruit/office-work/images/img_bg01.jpg") no-repeat center / cover;
    }*/
    
    .recruit_title02 {
        .recruit_title02__inner {
            &::before {
                background: $blue02 url("/recruit/office-work/images/img_ico01.svg") no-repeat center / 61px 41px;
                
                @include mq() {
                    background-size: 31px 21px;
                }
                
            }
        }
    }
}


/************************************************

	採用情報（営業）

*************************************************/
.p-recruit_salesman {
    /*.b-contents_bg01 {
        background: url("/recruit/salesman/images/img_bg01.jpg") no-repeat center / cover;
    }*/
    
    .recruit_title02 {
        .recruit_title02__inner {
            &::before {
                background: $blue02 url("/recruit/salesman/images/img_ico01.svg") no-repeat center / 54px 41px;
                
                @include mq() {
                    background-size: 27px 21px;
                }
                
            }
        }
    }
}


/************************************************

	採用情報（ドライバー）

*************************************************/
.p-recruit_driver {
    /*.b-contents_bg01 {
        background: url("/recruit/driver/images/img_bg01.jpg") no-repeat center / cover;
    }*/
}


/************************************************

	採用情報（作業員）

*************************************************/
.p-recruit_operator {
    /*.b-contents_bg01 {
        background: url("/recruit/operator/images/img_bg01.jpg") no-repeat center / cover;
    }*/
    
    .recruit_title02 {
        .recruit_title02__inner {
            &::before {
                background: $blue02 url("/recruit/operator/images/img_ico01.svg") no-repeat center / 42px 51px;
                
                @include mq() {
                    background-size: 21px 26px;
                }
                
            }
        }
    }
}


/************************************************

	採用フォーム

*************************************************/
.p-recruit_form {
    
    .b-head {
        background: url("/recruit/form/images/head_recruit_form.jpg") no-repeat center / cover;
    }
    
    .b-form__contents {
        margin-top: 0;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .b-sttl01 {
        margin-top: 80px;
        
        @include mq() {
            margin-top: 40px;
        }
        
        &::before {
            left: -20%;
            
            @include mq($tablet) {
                left: 50%;
                transform: translate(-120%, -20%);
            }
        }
    }
    
    .footer {
        margin-top: 75px;
    }
}