.p-sdgs {
	
	.b-head {
        background: url("/sdgs/images/head_sdgs.jpg") no-repeat center / cover;
    }
	
	.b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
	
	.b-sttl01 { 
    	&::before {
			background: url("../images/bg_item_service_full.png") no-repeat center/contain;
		}
		
		.b-sttl01__inner{
			background: #fff;
		}
	}
	
	.b-under_lead {
		text-align: left;
		line-height: 2.3;
		margin-top: 20px;
	}
	
	.sdgs_images01 {
	  margin-top: 45px;
	}

	@media screen and (max-width: 767px) {
	  .sdgs_images01 {
		margin-top: 20px;
	  }
	}
	
     .sdgs_btn01 {
        margin-top: 100px;
        text-align: center;
        
        @include mq() {
            margin-top: 50px;
        }
        
        a {
            position: relative;
            display: block;
            text-align: center;
            font-size: 3.8rem;
            @extend %bold;
            line-height: 1.3;
            color: #fff;
            border-radius: 100px;
            padding: 25px;
            max-width: 645px;
            margin: 0 auto;
            border: 2px solid;
            transition: color .3s, background .3s, border-color .3s, background .3s;
                        
            &::before {
                content: "";
                display: block;
                width: calc(100% - 0px);
                height: calc(100% - 0px);
                @include gradient_blue_h;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 100px;
                transition: opacity .3s;
                z-index: -1;
            }
			
            @include hover {
                color: $main;
				background: #fff;

                &::before {
                    opacity: 0;
                }
            }
            
            @include mq() {
                min-width: inherit;
                font-size: 2.2rem;
                padding: 15px;
            }
            
        }
    }
}
