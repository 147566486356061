.footer {
    margin-top: 220px;
    padding-bottom: 55px;
    //background: $main;
    position: relative;
    
    @include mq() {
        margin-top: 110px;
        font-size: 1.4rem;
        padding-bottom: 30px;
    }
        
    .footer__bg {
        width: 200%;
        height: 230%;
        @include gradient01;
        position: absolute;
        bottom: -70%;
        left: 50%;
        transform: translateX(-50%) rotate(-20deg);
        mix-blend-mode: multiply;
        
        @include mq(1030) {
            height: 200%;
        }
        
        @include mq() {
            bottom: auto;
            top: -60px;
            width: 320%;
        }
    }
    
    .footer__inner {
        max-width: 1250px;
        margin: 0 auto;
        padding-left: 75px;
        position: relative;
        z-index: 1;
        
        @include mq(1030) {
            padding: 0 20px;
        }
    }
    
    .footer__logo {
        
        a {
            display: inline-block;
            @include hover_op;
            
            @include mq() {
                max-width: 50%;
            }
        }
    }
    
    .footer__2col {
        display: -webkit-flex;
        display: flex;
        margin-top: 25px;
        
        @include mq(1270) {
            display: block;
        }
        
        @include mq() {
            margin-top: 0;
        }
    }
    
    .footer__links {
        color: #fff;
        margin-top: 40px;
        
        a {
            @include hover_op;
        }
        
        .footer__links__label {
            font-size: 2rem;
            line-height: 1.5;
            @extend %bold;
            position: relative;
            
            @include mq() {
                font-size: 1.6rem;
            }
            
            + .footer__links__label {
                margin-top: 20px;
                
                @include mq() {
                    margin-top: 0;
                }
            }
            
            + ul {
                
                @include mq() {
                    display: none;
                    padding-bottom: 20px;
                }
            }
            
            a {
                @include mq() {
                    display: block;
                    border-top: 1px solid;
                    padding: 10px 0;
                }
            }
        }
        
        .footer__links__cols {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            margin-right: 6%;
            
            @include mq(1270) {
                margin-right: 0;
            }
            
            @include mq() {
                display: block;
            }
        }
        
        .footer__links__col {
            margin-right: 6%;
            flex-shrink: 0;
            
            @include mq(1030) {
                flex-shrink: inherit;
            }
            
            @include mq() {
                margin-right: 0;
            }
            
            + .footer__links__col {
                
                @include mq() {
                    margin-top: 0;
                }
            }
            
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                @include mq() {
                    //margin-top: 20px;
                }
            }
            
            ul {
                margin-top: 10px;
                
                @include mq() {
                    margin-top: 0;
                }
            }
            
            &:last-child {
                
                @include mq(1030) {
                    margin-right: 0;
                }
            }
            
            li {
                
                + li {
                    margin-top: 10px;
                }
                
                &:not(.footer__links__label) {
                    
                    @include mq() {
                        padding-left: 20px;
                    }
                }
            }
            
            a {
                word-break: keep-all;
                
                @include mq(1030) {
                    word-break: normal;
                }
            }
        }
    }
    
    .footer__ico_plus {
        display: none;
        width: 30px;
        height: 30px;
        background: #fff;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        opacity: .9;
        cursor: pointer;
        @include hover_op;
        
        @include mq() {
            display: block;
        }
        
        &::before,
        &::after {
            content: '';
            display: block;
            width: 80%;
            height: 2px;
            background: $main;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
        &::after {
            transform: translate(-50%, -50%) rotate(-90deg);
            transition: transform .3s;
        }
        
        &.is-open {
            
            &::after {
                transform: translate(-50%, -50%) rotate(0);
            }
        }
    }
    
    .footer__bnrs {
        width: 307px;
        flex-shrink: 0;
        
        @include mq(1270) {
            width: 100%;
            margin-top: 40px;
            display: -webkit-flex;
            display: flex;
        }
        
        @include mq() {
            margin-top: 30px;
            flex-wrap: wrap;
        }

        li {
            
            @include mq(1270) {
                margin-right: 30px;
            }
            
            @include mq() {
                //margin-right: 20px;
                margin-right: calc(12% / 2);
                width: 47%;
                
                &:nth-child(2n) {
                    margin-right: 0;
                }
                
            }
            
            &:last-child {
                
                @include mq(1270) {
                    margin-top: 0;
                    margin-right: 0;
                }
                
                @include mq() {
                    width: 25%;
                    margin-top: 20px;
                }
                
            }

            + li {
                margin-top: 40px;
                
                @include mq(1270) {
                    margin-top: 0;
                }
            }
        }

        a {
            display: block;

            img {
                box-shadow: 3px 3px 2px rgba(0, 0, 0, .2);
            }
        }
    }
    
    .footer__copy {
        margin-top: 90px;
        font-size: 1.2rem;
        line-height: 1.3;
        color: #fff;
        text-align: center;
        
        @include mq() {
            margin-top: 40px;
        }
    }
}