.p-service {
	
	&.p-index {
		.tell_links {
			.tell_links__label {
				width: 480px;
				
				@include mq() {
					width: 100%;
				}
				
			}
		}
	}
	
    &.p-index--2 {
        
        .b-head {
            background: url("/service/images/head_service02.jpg") no-repeat center / cover;
            position: relative;
            
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: #666;
                position: absolute;
                top: 0;
                left: 0;
                mix-blend-mode: multiply;
            }
        }
    }
    
    .b-head {
        background: url("/service/images/head_service.jpg") no-repeat center / cover;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .cards {
        margin-top: 100px;
        color: $black01;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq() {
            margin-top: 55px;
            display: block;
        }

        .cards__card {
            background: rgba(255, 255, 255, .9);
            width: 48.5%;
            padding: 35px;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);

            @include mq() {
                width: 100%;
                padding: 20px 20px 40px;
            }

            &:nth-child(n+3) {
                margin-top: 3%;
            }

            &:nth-child(n+2) {

                @include mq() {
                    margin-top: 30px;
                }
            }
        }

        .cards__ico {
            width: 113px;
            height: 113px;
            margin: 0 auto;
            border-radius: 50%;
            @include gradient01;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 77.87%;
            }

            @include mq() {
                width: 75px;
                height: 75px;
            }
        }

        .cards__text_box {
            max-width: 380px;
            margin: 15px auto 0;
        }

        .cards__ttl {
            font-size: 3.3rem;
            line-height: 1.3;
            @extend %bold;
            text-align: center;
            color: $main;

            @include mq() {
                font-size: 2.2rem;
            }
        }

        .cards__text {
            margin-top: 15px;
            line-height: 1.6;
            font-size: 2rem;

            @include mq() {
                font-size: 1.8rem;
            }
        }

        .cards__links {
            display: -webkit-flex;
            display: flex;
            justify-content: center;

            @include mq(990) {
                //display: block;
            }

            .cards__links__inner {
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;

                @include mq(990) {
                    width: 190px;
                }

                @include mq() {
                    width: 160px;
                }
            }
        }

        .cards__link {
            margin-top: 35px;

            @include mq() {
                margin-top: 20px;
            }

            &:first-child {
                margin-right: 30px;

                @include mq(990) {
                    margin-right: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                position: relative;
                padding-left: 66px;
                font-size: 2rem;
                line-height: 1.3;
                color: $main;

                @include mq() {
                    font-size: 1.8rem;
                    padding-left: 46px;
                }

                @include hover {

                    .cards__link__ico {

                        &::before {
                            opacity: 1;
                        }
                    }

                    .cards__link__ico_arrow01 {

                        .svg_circle01 {
                            opacity: 1;
                        }

                        .svg_ling01 {
                            opacity: 0;
                        }

                        .svg_arrow01 {
                            opacity: 0;
                        }
                    }

                    .cards__link__text {

                        &::after {
                            width: 100%;
                        }
                    }
                }
            }

            &.cards__link--yellow {

                a {
                    color: $yellow01;
                }

                .cards__link__ico_arrow01 {

                    .svg_circle01 {
                        fill: url(#svg_gradient02);
                    }

                    .svg_ling01 {
                        fill: url(#svg_gradient02);
                    }

                    .svg_arrow01 {
                        fill: url(#svg_gradient02_s);
                    }
                }

                .cards__link__text {

                    &::after {
                        background: $yellow01;
                    }
                }
            }
        }

        .cards__link__ico {
            width: 46px;
            height: 46px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            //@include gradient01_h;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mq() {
                width: 30px;
                height: 30px;
            }
        }

        .cards__link__ico_arrow01 {
            width: 46px;
            height: 46px;
            z-index: 1;

            .svg_circle01 {
                width: 100%;
                height: 100%;
                fill: url(#svg_gradient01);
                opacity: 0;
                transition: opacity .3s;
            }

            .svg_ling01 {
                width: 100%;
                height: 100%;
                fill: url(#svg_gradient01);
                transition: opacity .3s;
            }

            .svg_arrow01 {
                width: 100%;
                height: 100%;
                fill: url(#svg_gradient01_s);
                transition: opacity .3s;
            }
        }

        .cards__link__text {
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 2px;
                background: $main;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: width .3s;
            }
        }
    }
    
    .tell_links {
        margin-top: 120px;
        background: #fff;
        border: 1px solid #b3b3b3;
        padding: 60px 40px 50px;
        color: #666;
        
        @include mq() {
            margin-top: 60px;
            padding: 30px 20px 25px;
        }
        
        .tell_links__inner {
            max-width: 1020px;
            margin: 0 auto;
        }
		
		.tell_links__box02 {
			margin-top: 40px;
			
			@include mq() {
				margin-top: 20px;
			}
			
		}
		
		.tell_links__box03 {
			display: -webkit-flex;
			display: flex;
			flex-wrap: wrap;
			//margin-top: 20px;
			
			@include mq() {
				display: block;
			}
			
			+ .tell_links__box03 {
				margin-top: 10px;
			}
			
		}
		
        .tell_links__ttl {
            font-size: 3.3rem;
            line-height: 1.3;
            @extend %bold;
            position: relative;
            z-index: 1;
            
            @include mq() {
                font-size: 2rem;
            }
            
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    
                }
            }
        }
        
        .tell_links__ico {
            background: #fff;
            position: relative;
            display: inline-block;
            padding-left: 80px;
            padding-right: 20px;
            
            @include mq() {
                padding-left: 40px;
                padding-right: 10px;
            }
            
            &::before {
                content: '';
                display: block;
                background: #666 url('../images/ico_tell.svg') no-repeat center / 28px 36px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    width: 30px;
                    height: 30px;
                    background-size: 14px 18px;
                }
            }
        }
        
        .tell_links__ico--mail {
            background: #fff;
            position: relative;
            display: inline-block;
            padding-left: 80px;
            padding-right: 20px;
            
            @include mq() {
                padding-left: 40px;
                padding-right: 10px;
            }
            
            &::before {
                content: "";
                display: block;
                background: #666 url("../images/ico_mail-gray.svg") no-repeat center / 36px 29px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    width: 30px;
                    height: 30px;
                    background-size: 18px 15px;
                }
                
            }
            
        }
        
        .tell_links__list {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            li {
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }
        }
        
        .tell_links__label {
            font-size: 2.3rem;
            line-height: 1.5;
            @extend %bold;
            //width: 480px;
            flex-shrink: 0;
            margin-right: 100px;
            
            @include mq() {
                font-size: 1.6rem;
                margin-right: 0;
                flex-shrink: inherit;
				width: 100%;
            }
			
			&.tell_links__label--round {
				position: relative;
				padding-left: 1.2em;
				
				&::before {
					content: "";
					display: block;
					width: .9em;
					height: .9em;
					background: #999;
					border-radius: 50%;
					position: absolute;
					top: .2em;
					left: 0;
				}
				
				@include mq() {
					&::before {
						top: .25em;
					}
				}
				
			}
			
			&.tell_links__label--fixed {
				width: 613px;
			}
			
			&.tell_links__label--no_fixed {
				width: auto;
			}
			
        }
        
        .tell_links__number {
            font-size: 2.5rem;
            line-height: 1.3;
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
    }
    
    /* 許可証一覧 */
    .license_lists {
        margin-top: 120px;
        background: #fff;
        border: 1px solid #b3b3b3;
        padding: 70px 40px 80px;
        
        @include mq() {
            margin-top: 60px;
            padding: 35px 20px 40px;
        }
        
        .license_lists__inner {
            max-width: 1020px;
            margin: 0 auto;
        }
        
        .license_lists__wrap {
            margin-top: 50px;
            
            @include mq() {
                margin-top: 25px;
            }
        }
        
        .license_lists__title {
            font-size: 2.4rem;
            line-height: 1;
            margin-bottom: 25px;
            
            @include mq() {
                font-size: 2.2rem;
                margin-bottom: 13px;
            }
            
        }
        
        .license_lists__table_wrap {
            @include mq(930) {
                overflow-x: scroll;
            }
        }
        
        .license_lists__table_box {
            @include mq(930) {
                width: 1000px;
            }
        }
        
        .license_lists__table {
            border-collapse: separate;
            
            th, td {
                box-sizing: border-box;
            }
            
            thead {
                th {
                    padding: 10px 20px;
                    font-size: 2.2rem;
                    line-height: 1;
                    color: $main;
                    text-align: center;
                    border-top: 3px solid $main;
                    border-bottom: 3px solid $main;
                    
                    &:first-of-type {
                        width: 6em;
                    }
                    
                    @include mq() {
                        padding: 10px;
                        font-size: 2rem;
                    }
                    
                }
            }
            
            tbody {                
                tr {
                    background: #e5f0f7;
                    
                }
                
                td {
                    text-align: center;
                    font-size: 2rem;
                    border-top: 6px solid #fff;
                    padding: 10px 0;
                    
                    @include mq() {
                        font-size: 1.8rem;
                    }
                    
                }
            }
            
        }
        
        .license_lists__link {
            transition: opacity .3s;
            
            @include hover {
                opacity: .7;
            }
            
        }
        
        .license_lists__ico--pdf {
            padding-right: 35px;
            position: relative;
            
            &::after {
                content: "";
                display: block;
                background: url("../images/ico_pdf.svg") no-repeat right center / 28px 36px;
                width: 28px;
                height: 36px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    background-size: 23px 31px;
                    width: 23px;
                    height: 31px;
                }
                
            }
            
        }
        
        
    }
    
    
    
    .info {
        margin-top: 120px;
        
        @include mq() {
            margin-top: 60px;
        }
        
        .info__lead {
            text-align: center;
            font-size: 3.3rem;
            line-height: 1.8;
            @extend %bold;
            
            @include mq() {
                font-size: 2.2rem;
                line-height: 1.75;
                text-align: left;
            }
        }
        
        .info__btn {
            margin-top: 70px;
            text-align: center;
            
            @include mq() {
                margin-top: 35px;
            }
            
            .info__btn__inner {
                position: relative;
                z-index: 1;
            }
            
            a {
                max-width: 640px;
                text-align: center;
                display: block;
                margin: 0 auto;
                border-radius: 100px;
                padding: 25px;
                font-size: 3.8rem;
                line-height: 1.3;
                color: #fff;
                @extend %bold;
                @include gradient_blue_h;
                transition: color .3s, background .3s;
                position: relative;
                
                @include mq() {
                    padding: 15px;
                    font-size: 2.2rem;
                }
                
                @include hover {
                    color: $main;
                    
                    &::before {
                        opacity: 1;
                    }
                }
                
                &::before {
                    content: '';
                    display: block;
                    background: #fff;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 100px;
                    opacity: 0;
                    transition: opacity .3s;
                }
            }
        }
    }
        
    .footer {
        margin-top: 75px;
        
        .footer__inner {
            //transform: translateX(75px);
            
            @include mq(1420) {
                transform: none;
            }
        }
    }
}

.p-automobile_recycle, .p-service_individual, .p-scrap, .p-disporsal, .p-service_disassembly {
    .b-head {
        .b-head__inner {
            margin-top: 49px;
            
            @include mq() {
                margin-top: 0;
            }
        }
        
        .b-head__ico {
            img {
                width: 100px;
                height: 100px;
                
                @include mq() {
                    width: 70px;
                    height: 70px;
                }
                
            }
        }
        
        .b-head__ttl {
            margin-top: 0;
            border: none;
            
            @include mq() {
                padding: 2px 20px;
            }
            
        }
    }
}


/************************************************

	自動車リサイクル

*************************************************/
.p-automobile_recycle {
    
    .b-head {
        background: url("/service/automobile-recycle/images/head_automobile_recycle.jpg") no-repeat center / cover;
    }
    
    .b-sttl01 {
        
        &::before {
            background: url("../images/bg_item_service_full.png") no-repeat center / contain;
        }
        
        .b-sttl01__inner {
            background: #fff;
        }
    }
    
    .automobile_recycle_btn01 {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        
        @include mq() {
            margin-top: 45px;
        }
        
        a {
            display: block;
            text-align: center;
            font-size: 3rem;
            font-weight: bold;
            color: #fff;
            background: #de2525;
            border: 2px solid #de2525;
            border-radius: 50px;
            padding: 23px 78px;
            line-height: 1.3;
            transition: .3s all;
            
            @include mq() {
                font-size: 2rem;
                padding: 15px 40px;
            }
            
            @include hover {
                color: #de2525;
                background: #fff;
            }
            
        }
        
    }
    
    .automobile_recycle_images01 {
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 45px;
        
        @include mq() {
            margin-top: 20px;
        }
        
    }
    
    .automobile_recycle_bnr {
        display: flex;
        align-items: center;
        margin-top: 60px;
        
        @include mq() {
            display: block;
            margin-top: 30px;
        }
        
        p {
            margin-right: calc(16% / 2);
            
            &:last-child {
                margin-right: 0;
            }
            
            @include mq() {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
            }
            
            a {
                display: block;
                transition: .3s opacity;
                
                @include hover {
                    opacity: .7;
                }
                
            }
            
            img {
                -moz-box-shadow: 4px 4px 3px 0px rgba(186, 186, 186, 0.75);
                -webkit-box-shadow: 4px 4px 3px 0px rgba(186, 186, 186, 0.75);
                -ms-box-shadow: 4px 4px 3px 0px rgba(186, 186, 186, 0.75);
                box-shadow: 4px 4px 3px 0px rgba(186, 186, 186, 0.75);
            }
        }
        
    }
    
    .s-sec02 {
        margin-top: 140px;
        
        @include mq() {
            margin-top: 70px;
        }
        
    }
    
    .s-sec02_box {
        background: rgba(255, 255, 255, .9);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        margin-top: 120px;
        
        @include mq() {
            padding: 20px;
            margin-top: 60px;
        }
        
        &:first-child {
            margin-top: 0;
        }
        
        &.automobile_recycle_flow {
            padding: 75px 100px 55px;
            
            @include mq() {
                padding: 20px;
            }
            
        }
        
    }
    
    .s-sec02_box__inner {
        margin-top: 50px;
        padding-left: 25px;
        
        @include mq() {
            margin-top: 25px;
            padding-left: 0;
        }
        
    }
    
    .s-sec02_text01 {
        font-size: 2rem;
        
        @include mq() {
            font-size: 1.8rem;
        }
        
    }
    
    .s-sec02_box__contact {
        margin-top: 80px;
        
        @include mq() {
            margin-top: 40px;
        }
        
        .s-sec02_box__contact__text01 {
            font-size: 2rem;
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
        
        .s-sec02_box__contact__2col {
            display: -webkit-flex;
            display: flex;
            
            @include mq() {
                display: block;
            }
        }
        
        .s-sec02_box__contact__r {
            width: 180px;
            flex-shrink: 0;
            text-align: center;
            margin-left: 40px;
            
            @include mq() {
                margin-left: 0;
                margin-top: 20px;
            }
        }
        
        .s-sec02_box__contact__link01 {
            word-break: break-all;
            line-height: 1.5;
        }
        
        .s-sec02_box__contact__sttl01 {
            margin-top: 30px;
            font-size: 2rem;
            font-weight: bold;
        }
        
        .s-sec02_box__contact__list {
            margin-top: 5px;

            @include mq() {
                margin-top: 5px;
            }

            li {
                position: relative;
                padding-left: 1em;
                font-size: 2rem;

                @include mq() {
                    font-size: 1.8rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                }

                &::before {
                    content: "-";
                    display: block;
                    position: absolute;
                    left: 0;
                }

            }
        }
    }
    
    .service_individual_contact {
        margin-top: 120px;
        background: #fff;
        border: 1px solid #b3b3b3;
        padding: 60px 40px 50px;
        color: #666;
        
        @include mq() {
            margin-top: 60px;
            padding: 30px 20px 25px;
        }
        
        .service_individual_contact__inner {
            max-width: 1020px;
            margin: 0 auto;
        }
        
        .service_individual_contact__box01 {
            margin-top: 60px;
            
            &:first-of-type {
                margin-top: 0;
            }
            
            @include mq() {
                margin-top: 30px;
            }
            
        }
        
		.service_individual_contact__box02 {
			margin-top: 40px;
			
			@include mq() {
				margin-top: 20px;
			}
			
		}
		
		.service_individual_contact__box03 {
			display: -webkit-flex;
			display: flex;
			flex-wrap: wrap;
			//margin-top: 20px;
			
			@include mq() {
				display: block;
			}
			
			+ .service_individual_contact__box03 {
				margin-top: 10px;
			}
			
		}
		
        .service_individual_contact__title01 {
            font-family: $gothic_bold;
            text-align: center;
            font-size: 3.8rem;
            margin-bottom: 30px;
            color: $main;
            
            @include mq() {
                font-size: 2.2rem;
                line-height: 1.5;
                margin-bottom: 20px;
            }
            
        }
        
        .service_individual_contact__title02 {
            font-family: $gothic_bold;
            font-size: 3.3rem;
            line-height: 1.3;
            position: relative;
            
            @include mq() {
                font-size: 2rem;
            }
            
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            
            span {
                background: #fff;
                position: relative;
                display: inline-block;
                padding-right: 20px;
                padding-left: 80px;
                
                @include mq() {
                    padding-left: 40px;
                    padding-right: 10px;
                }
                
            }
            
            .service_individual_contact__title02--ico_tel {
                &::before {
                    content: "";
                    display: block;
                    background: #666 url("../images/ico_tell.svg") no-repeat center / 28px 36px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    
                    @include mq() {
                        width: 30px;
                        height: 30px;
                        background-size: 14px 18px;
                    }
                    
                }
            }
            
            .service_individual_contact__title02--ico_mail {
                &::before {
                    content: "";
                    display: block;
                    background: #666 url("../images/ico_mail-gray.svg") no-repeat center / 36px 29px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    
                    @include mq() {
                        width: 30px;
                        height: 30px;
                        background-size: 18px 15px;
                    }
                    
                }
            }
            
        }
        
        .service_individual_contact__list01 {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            li {
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }
            
        }
        
        .service_individual_contact__label {
            font-family: $gothic_bold;
            font-size: 2.3rem;
            font-weight: bold;
            line-height: 1.5;
            width: 480px;
            flex-shrink: 0;
            margin-right: 100px;
            
            @include mq() {
                font-size: 1.6rem;
                margin-right: 0;
                flex-shrink: inherit;
				width: 100%;
            }
            
			&.service_individual_contact__label--round {
				position: relative;
				padding-left: 1.2em;
				
				&::before {
					content: "";
					display: block;
					width: .9em;
					height: .9em;
					background: #999;
					border-radius: 50%;
					position: absolute;
					top: .2em;
					left: 0;
				}
				
				@include mq() {
					&::before {
						top: .25em;
					}
				}
			}
			
			&.service_individual_contact__label--no_fixed {
				width: auto;
			}
			
        }
        
        .service_individual_contact__number {
            font-size: 2.5rem;
            line-height: 1.3;
            
            @include mq() {
                font-size: 1.8rem;
            }
            
        }
        
        .service_individual_contact__btn {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            a {
                display: inline-block;
                background: $red01;
                border: 2px solid $red01;
                color: #fff;
                font-size: 2.2rem;
                font-weight: bold;
                line-height: 1;
                text-align: center;
                border-radius: 100px;
                padding: 15px;
                min-width: 280px;
                transition: .3s all;
                
                @include mq() {
                    font-size: 1.8rem;
                    padding: 10px;
                    min-width: 230px;
                }
                
                span {
                    position: relative;
                    padding-right: 25px;
                    
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-top: 3px solid #fff;
                        border-right: 3px solid #fff;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                        
                    }
                    
                    @include mq() {
                        padding-right: 20px;
                        
                        &::after {
                            width: 8px;
                            height: 8px;
                            border-top-width: 2px;
                            border-right-width: 2px;
                        }
                        
                    }
                    
                }
                
                @include hover {
                    background: #fff;
                    color: $red01;
                    
                    span {
                        &::after {
                            border-top-color: $red01;
                            border-right-color: $red01;
                        }
                    }
                    
                }
            }
        }
    }
    
}


/************************************************

	個人のお客様

*************************************************/
.p-service_individual {
    .b-head {
        background: url("/service/automobile-recycle/customer/images/customer.jpg") no-repeat center / cover;
        
        /*&.js-active {
            background: url("/service/automobile-recycle/customer/images/head_individual.jpg") no-repeat center / cover;
        }*/
    }
    
    .b-sec_under {
        padding-top: 150px;
        margin-top: 0;
        
        @include mq(820) {
            padding-top: 75px;
        }
        
        @include mq() {
            padding-top: 50px;
        }
        
    }
    
    .service_individual_tab {
        display: flex;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 50px;
        
        @include mq() {
            display: block;
            margin-bottom: 25px;
        }
        
        .service_individual_tab__list {
            width: 46%;
            margin-right: calc(12% / 2);
            text-align: center;
            background: $blue02;
            border: 2px solid $blue02;
            color: #fff;
            padding: 20px 10px;
            box-sizing: border-box;
            font-size: 2rem;
            font-weight: bold;
            border-radius: 10px;
            cursor: pointer;
            transition: .3s all;
            
            &:last-child {
                margin-right: 0;
            }
            
            @include hover {
                background: #fff;
                color: $blue02;
            }
            
            &.js-current {
                background: #fff;
                color: $blue02;
            }
            
            @include mq() {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
                padding: 10px;
                font-size: 1.8rem;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
            }
            
        }
        
    }
    
    .s-sec01_box01 {
        background: rgba(255, 255, 255, .9);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        margin-top: 60px;
        position: relative;
                
        @include mq() {
            padding: 20px;
            margin-top: 45px;
        }
        
        &:first-child {
            margin-top: 0;
        }
                
    }
    
    .service_individual_box01 {
        display: none;
        
        &.js-show {
            display: block;
        }
        
    }
    
    .service_individual_col01 {
        display: flex;
        
        @include mq() {
            display: block;
        }
        
        .service_individual_col01__box {
            margin-right: calc(12% / 2);
            
            &:first-child {
                width: 240px;
                flex-basis: 240px;
                flex-shrink: 1;
            }
            
            &:last-child {
                margin-right: 0;
            }
            
            @include mq() {
                margin-right: 0;
                
                &:first-child {
                    width: 100%;
                    flex-shrink: 0;
                }
                
                
            }
            
        }
        
    }
    
    /* 買取の流れ */
    #service_individual--flow {
        .s-sec01_box01 {
            background: rgba(255, 255, 255, .9);
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
            padding: 75px 100px;
            box-sizing: border-box;
            margin-top: 90px;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 25px 25px 0 25px;
                border-color: $main transparent transparent transparent;
                position: absolute;
                left: 50%;
                bottom: -60px;
                transform: translateX(-50%);

                @include mq() {
                    border-width: 13px 12.5px 0 12.5px;
                    bottom: -30px;
                }

            }

            @include mq() {
                padding: 20px;
                margin-top: 45px;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }

        }
    }
    
    .service_individual_flow__title01 {
        span {
            display: block;
            background: $main;
            color: #fff;
            text-align: center;
            padding: 15px 10px;
            line-height: 1;
            font-size: 2rem;
            
            @include mq() {
                padding: 10px;
                font-size: 1.8rem;
            }
            
        }
        
        h2 {
            margin-top: 50px;
            font-size: 2.2rem;
            text-align: center;
            
            @include mq() {
                margin-top: 15px;
                font-size: 2rem;
            }
            
        }
        
    }
    
    .service_individual_flow__text01 {
        
        @include mq() {
            margin-top: 10px;
        }
        
        p {
            font-size: 2rem;
            line-height: 1.6;
            
            @include mq() {
                font-size: 1.8rem;
            }
            
        }
        
    }
    
    .service_individual_flow_tel {
        margin-top: 30px;
        
        @include mq() {
            margin-top: 15px;
        }
        
        li {
            position: relative;
            padding-left: 1em;
            font-size: 2rem;
            font-weight: bold;
            
            @include mq() {
                font-size: 1.8rem;
                margin-bottom: 15px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
            }
            
            &::before {
                content: "-";
                display: block;
                position: absolute;
                left: 0;
            }
            
        }
    }
    
    .service_individual_flow_btn {
        margin-top: 20px;
        
        a {
            display: block;
            background: $red01;
            border: 2px solid $red01;
            color: #fff;
            font-size: 2.2rem;
            line-height: 1;
            font-weight: bold;
            text-align: center;
            border-radius: 100px;
            padding: 15px 10px;
            transition: .3s all;
            
            @include hover {
                background: #fff;
                color: $red01;
                
                span {
                    &::after {
                        border-color: $red01;
                    }
                }
                
            }
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
        
        span {
            position: relative;
            padding-right: 25px;
            
            &::after {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 3px solid #fff;
                border-right: 3px solid #fff;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
            
            @include mq() {
                padding-right: 20px;
                
                &::after {
                    width: 8px;
                    height: 8px;
                    border-width: 2px;
                }
                
            }
            
        }
        
    }
    
    
    /* 必要書類について */    
    .service_individual_document__title01 {
        text-align: center;
        font-size: 2.5rem;
        line-height: 1.3;
        color: #fff;
        background: $main;
        padding: 15px;
        border-radius: 100px;
        margin-bottom: 30px;
        
        @include mq() {
            font-size: 2.1rem;
            margin-bottom: 15px;
        }
        
    }
    
    .service_individual_document__list01 {
        li {
            position: relative;
            font-size: 2rem;
            padding-left: 18px;
            margin-bottom: 5px;
            
            &:last-child {
                margin-bottom: 0;
            }
            
            @include mq() {
                font-size: 1.8rem;
                line-height: 1.6;
                margin-bottom: 10px;
            }
            
            &::before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                background: $blue02;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 9px;
                
                @include mq() {
                    width: 12px;
                    height: 12px;
                    top: 7px;
                }
                
            }
            
        }
    }
    
    
    /* よくある質問 */
    .service_individual_qa {
        margin-top: 120px;
        
        @include mq() {
            margin-top: 60px;
        }
        
    }
    
    .service_individual_qa__title01 {
        font-family: $gothic_bold;
        font-size: 4.8rem;
        text-align: center;
        color: $blue02;
        position: relative;
        padding-bottom: 5px;
        
        &::before {
            content: "";
            display: block;
            width: 60px;
            height: 4px;
            background: $blue02;
            border-radius: 50px;
            position: absolute;
            left: calc(50% - 30px);
            bottom: 0;
        }
        
        
        @include mq() {
            font-size: 3.2rem;
            
            &::before {
                width: 30px;
                height: 3px;
                left: calc(50% - 15px);
            }
            
        }
        
    }
    
    .service_individual_qa__wrap {
        margin-top: 50px;
        
        @include mq() {
            margin-top: 30px;
        }
        
    }
    
    .service_individual_qa__box {
        margin-top: 50px;
        
        &:first-of-type {
            margin-top: 0;
        }
        
    }
    
    
    .service_individual_qa--q {
        display: flex;
        
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $main;
            color: #fff;
            font-size: 2.8rem;
            line-height: 1;
            font-weight: bold;
            padding: 25px 15px;
            flex-basis: 82px;
            flex-shrink: 0;
            
            @include mq() {
                font-size: 2.2rem;
                padding: 10px;
                flex-basis: 61px;
            }
            
        }
        
        h3 {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 15px;
            font-size: 2.6rem;
            background: #cff3f5;
            
            @include mq() {
                padding: 10px;
                font-size: 2rem;
                line-height: 1.3;
            }
            
        }
        
    }
    
    .service_individual_qa--a {
        padding: 20px 15px;
        font-size: 2rem;
        background: #f7f7f7;
        
        @include mq() {
            padding: 10px 15px;
            font-size: 1.8rem;
        }
        
        p {
            @include mq() {
                line-height: 1.6;
            }
        }
        
    }
    
    /* お問い合わせコンテンツ */
    .service_individual_contact {
        margin-top: 120px;
        background: #fff;
        border: 1px solid #b3b3b3;
        padding: 60px 40px 50px;
        color: #666;
        
        @include mq() {
            margin-top: 60px;
            padding: 30px 20px 25px;
        }
        
        .service_individual_contact__inner {
            max-width: 1020px;
            margin: 0 auto;
        }
        
        .service_individual_contact__box01 {
            margin-top: 60px;
            
            &:first-of-type {
                margin-top: 0;
            }
            
            @include mq() {
                margin-top: 30px;
            }
            
        }
        
		.service_individual_contact__box02 {
			margin-top: 40px;
			
			@include mq() {
				margin-top: 20px;
			}
			
		}
		
		.service_individual_contact__box03 {
			display: -webkit-flex;
			display: flex;
			flex-wrap: wrap;
			//margin-top: 20px;
			
			@include mq() {
				display: block;
			}
			
			+ .service_individual_contact__box03 {
				margin-top: 10px;
			}
			
		}
		
        .service_individual_contact__title01 {
            font-family: $gothic_bold;
            text-align: center;
            font-size: 3.8rem;
            margin-bottom: 30px;
            color: $main;
            
            @include mq() {
                font-size: 2.2rem;
                line-height: 1.5;
                margin-bottom: 20px;
            }
            
        }
        
        .service_individual_contact__title02 {
            font-family: $gothic_bold;
            font-size: 3.3rem;
            line-height: 1.3;
            position: relative;
            
            @include mq() {
                font-size: 2rem;
            }
            
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            
            span {
                background: #fff;
                position: relative;
                display: inline-block;
                padding-right: 20px;
                padding-left: 80px;
                
                @include mq() {
                    padding-left: 40px;
                    padding-right: 10px;
                }
                
            }
            
            .service_individual_contact__title02--ico_tel {
                &::before {
                    content: "";
                    display: block;
                    background: #666 url("../images/ico_tell.svg") no-repeat center / 28px 36px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    
                    @include mq() {
                        width: 30px;
                        height: 30px;
                        background-size: 14px 18px;
                    }
                    
                }
            }
            
            .service_individual_contact__title02--ico_mail {
                &::before {
                    content: "";
                    display: block;
                    background: #666 url("../images/ico_mail-gray.svg") no-repeat center / 36px 29px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    
                    @include mq() {
                        width: 30px;
                        height: 30px;
                        background-size: 18px 15px;
                    }
                    
                }
            }
            
        }
        
        .service_individual_contact__list01 {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            li {
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }
            
        }
        
        .service_individual_contact__label {
            font-family: $gothic_bold;
            font-size: 2.3rem;
            font-weight: bold;
            line-height: 1.5;
            width: 480px;
            flex-shrink: 0;
            margin-right: 100px;
            
            @include mq() {
                font-size: 1.6rem;
                margin-right: 0;
                flex-shrink: inherit;
				width: 100%;
            }
            
			&.service_individual_contact__label--round {
				position: relative;
				padding-left: 1.2em;
				
				&::before {
					content: "";
					display: block;
					width: .9em;
					height: .9em;
					background: #999;
					border-radius: 50%;
					position: absolute;
					top: .2em;
					left: 0;
				}
				
				@include mq() {
					&::before {
						top: .25em;
					}
				}
				
			}
			
			&.service_individual_contact__label--fixed {
				width: 613px;
			}
			
			&.service_individual_contact__label--no_fixed {
				width: auto;
			}
			
        }
        
        .service_individual_contact__number {
            font-size: 2.5rem;
            line-height: 1.3;
            
            @include mq() {
                font-size: 1.8rem;
            }
            
        }
        
        .service_individual_contact__btn {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            a {
                display: inline-block;
                background: $red01;
                border: 2px solid $red01;
                color: #fff;
                font-size: 2.2rem;
                font-weight: bold;
                line-height: 1;
                text-align: center;
                border-radius: 100px;
                padding: 15px;
                min-width: 280px;
                transition: .3s all;
                
                @include mq() {
                    font-size: 1.8rem;
                    padding: 10px;
                    min-width: 230px;
                }
                
                span {
                    position: relative;
                    padding-right: 25px;
                    
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-top: 3px solid #fff;
                        border-right: 3px solid #fff;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                        
                    }
                    
                    @include mq() {
                        padding-right: 20px;
                        
                        &::after {
                            width: 8px;
                            height: 8px;
                            border-top-width: 2px;
                            border-right-width: 2px;
                        }
                        
                    }
                    
                }
                
                @include hover {
                    background: #fff;
                    color: $red01;
                    
                    span {
                        &::after {
                            border-top-color: $red01;
                            border-right-color: $red01;
                        }
                    }
                    
                }
            }
        }
    }
}


/************************************************

	鉄・非鉄金属スクラップ

*************************************************/
.p-scrap {
    .b-head {
        background: url("/service/scrap/images/head_scrap.jpg") no-repeat center / cover;
    }
    
    .b-sttl01 {
        
        &::before {
            background: url("../images/bg_item_service_full.png") no-repeat center / contain;
        }
        
        .b-sttl01__inner {
            background: #fff;
        }
    }
    
    .b-under_col01 {
        margin-top: 105px;
        display: flex;
        justify-content: center;
        
        @include mq() {
            margin-top: 53px;
            display: block;
        }
        
        .b-under_col01__box {
            margin-right: calc(12% / 2);
            
            &:last-child {
                margin-right: 0;
            }
            
            @include mq() {
                margin-right: 0;
                
                &:last-child {
                    margin-top: 15px;
                }
                
            }
            
        }
        
    }
    
    .b-under_lead {
        text-align: left;
        margin-top: 0;
    }
    
    .s-sec02 {
        margin-top: 120px;
        
        @include mq() {
            margin-top: 60px;
        }
        
    }
    
    .s-sec02_text01 {
        font-size: 2rem;
        
        @include mq() {
            font-size: 1.8rem;
        }
        
    }
    
    .s-sec02_box {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        margin-top: 60px;
        position: relative;
                
        @include mq() {
            padding: 20px;
            margin-top: 45px;
        }
        
        &:first-child {
            margin-top: 0;
        }
    }
    
    .s-sec02_box__inner {
        margin-top: 50px;
        padding-left: 25px;
        
        @include mq() {
            margin-top: 25px;
            padding-left: 0;
        }
    }
    
    
    .scrap_img_list01 {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        
        @include mq() {
            margin-top: 20px;
        }
        
        li {
            width: 31.3%;
            margin-right: calc(9% / 3);
            margin-bottom: 25px;
            position: relative;
            
            &:nth-child(3n) {
                margin-right: 0;
            }
            
            @include mq() {
                width: 48%;
                margin-right: calc(8% / 2);
                margin-bottom: 10px;
                
                &:nth-child(2n) {
                    margin-right: 0;
                }
                
                &:nth-child(3n) {
                    margin-right: auto;
                }
            }
            
            .scrap_img_list01--title {
                max-width: 200px;
                width: 100%;
                padding: 5px;
                position: absolute;
                bottom: 20px;
                font-size: 2rem;
                line-height: 1.3;
                text-align: center;
                color: #fff;
                @extend %bold;
                @include gradient_blue_deg;
                
                @include mq(895) {
                    max-width: 115px;
                    bottom: 10px;
                    font-size: 1.4rem;
                }
                
            }
            
            
        }
        
    }
    
    .scrap_img_list02 {
        display: flex;
        margin-top: 35px;
        
        @include mq() {
            flex-wrap: wrap;
            margin-top: 18px;
        }
        
        li {
            width: 25%;
            margin-right: calc(8% / 4);
            position: relative;
            
            &:last-child {
                margin-right: 0;
            }
            
            @include mq() {
                width: 48%;
                margin-right: calc(8% / 2);
                margin-bottom: 10px;
                
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
            
            .scrap_img_list02--title {
                max-width: 200px;
                width: 100%;
                padding: 5px;
                position: absolute;
                bottom: 20px;
                font-size: 2rem;
                line-height: 1.3;
                text-align: center;
                color: #fff;
                @extend %bold;
                @include gradient_blue_deg;
                
                @include mq(1100) {
                    max-width: 125px;
                    bottom: 10px;
                    font-size: 1.4rem;
                }
                
            }
            
        }
        
    }
    
    .scrap_text01 {
        @include mq() {
            margin-top: 5px;
        }
    }
    
    .scrap_btn01 {
        margin-top: 100px;
        text-align: center;
        
        @include mq() {
            margin-top: 50px;
        }
        
        a {
            position: relative;
            display: block;
            text-align: center;
            font-size: 3.8rem;
            @extend %bold;
            line-height: 1.3;
            color: #fff;
            border-radius: 100px;
            padding: 25px;
            max-width: 645px;
            margin: 0 auto;
            border: 2px solid;
            transition: color .3s, background .3s, border-color .3s;
            
            @include hover {
                color: $main;

                &::before {
                    opacity: 0;
                }
            }
            
            @include mq() {
                min-width: inherit;
                font-size: 2.2rem;
                padding: 15px;
            }
            
            &::before {
                content: "";
                display: block;
                width: calc(100% - 0px);
                height: calc(100% - 0px);
                @include gradient_blue_h;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 100px;
                transition: opacity .3s;
                z-index: -1;
            }
            
        }
    }
        
    
    /* お問い合わせコンテンツ */
    .tell_links {
        .tell_links__ttl02 {
            font-family: $gothic_bold;
            font-size: 3.3rem;
            line-height: 1.3;
            position: relative;
            z-index: 1;
            
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            
            @include mq() {
                font-size: 2rem;
            }
            
            span {
                background: #fff;
                position: relative;
                display: inline-block;
                padding-right: 20px;
                
                @include mq() {
                    padding-right: 10px;
                }
                
            }
            
        }
        
        .tell_links__box01 {
            margin-top: 60px;
            
            @include mq() {
                margin-top: 30px;
            }
            
        }
		
        .tell_links__btn {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            a {
                position: relative;
                background: #fff;
                display: inline-block;
                text-align: center;
                font-size: 2.2rem;
                font-weight: bold;
                line-height: 1;
                color: #fff;
                border-radius: 100px;
                padding: 15px;
                min-width: 250px;
                border: 2px solid;
                transition: color .3s, background .3s, border-color .3s;
                
                @include mq() {
                    font-size: 1.8rem;
                    min-width: 200px;
                }
                
            }
            
            span {
                position: relative;
                padding-right: 25px;
                
                &::after {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    transition: border-color .3s;
                }
                
                @include mq() {
                    padding-right: 20px;
                    
                    &::after {
                        width: 8px;
                        height: 8px;
                        border-width: 2px;
                    }
                    
                }
                
            }
            
            
            &.tell_links__btn--form {
                a {
                    position: relative;
                    
                    @include hover {
                        color: $blue01;
                        
                        &::before {
                            opacity: 0;
                        }
                        
                        span {
                            &::after {
                                border-color: $blue01;
                            }
                        }
                        
                    }
                    
                    &::before {
                        content: "";
                        display: block;
                        width: calc(100% - 0px);
                        height: calc(100% - 0px);
                        @include gradient_blue_h;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 100px;
                        transition: opacity .3s;
                    }
                }
            }
            
        }
        
    }
    
}



/************************************************

	産業廃棄物処理

*************************************************/
.p-disporsal {
    .b-head {
        background: url("/service/disporsal/images/head_disporsal.jpg") no-repeat center / cover;
    }
    
    .b-sttl01 {
        
        &::before {
            background: url("../images/bg_item_service_full.png") no-repeat center / contain;
        }
        
        .b-sttl01__inner {
            background: #fff;
        }
    }
    
    .b-sttl04 {
        margin-bottom: 25px;
        
        @include mq() {
            margin-bottom: 13px;
        }
        
    }
    
    .s-sec02 {
        margin-top: 75px;
        
        @include mq() {
            margin-top: 38px;
        }
        
    }
    
    .s-sec03 {
        margin-top: 120px;
        
        @include mq() {
            margin-top: 60px;
        }
        
    }
    
    .s-sec03_box01 {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        margin-top: 60px;
        position: relative;
                
        @include mq() {
            padding: 20px;
            margin-top: 45px;
        }
        
        &:first-child {
            margin-top: 0;
        }
    }
    
    .s-sec03_box01__inner {
        margin-top: 50px;
        padding-left: 25px;
        
        @include mq() {
            margin-top: 25px;
            padding-left: 0;
        }
    }
    
    .s-sec03_text01 {
        font-size: 2rem;
        
        @include mq() {
            font-size: 1.8rem;
        }
        
    }
    
    .s-sec03_text02 {
        font-size: 2rem;
        margin-top: 25px;
        
        @include mq() {
            font-size: 1.8rem;
            margin-top: 13px;
        }
        
    }
    
    .disporsal_title01 {
        color: $blue02;
        font-size: 2.8rem;
        line-height: 1.5;
        margin-bottom: 15px;
        
        @include mq() {
            font-size: 2.2rem;
            margin-bottom: 10px;
        }
        
    }
    
    .disporsal_small01 {
        font-size: 1.6rem;
        @extend %bold;
        text-indent: -1em;
        padding-left: 1em;
        margin-top: 5px;
    }
    
    .disporsal_col01 {
        display: flex;
        
        @include mq(1010) {
            display: block;
        }
        
    }
    
    .disporsal_col01__box {
        width: 48%;
        margin-right: calc(12% / 2);
        
        &:last-of-type {
            margin-right: 0;
            flex-basis: 430px;
            flex-shrink: 0;
        }
        
        @include mq(1010) {
            width: 100%;
            margin-right: 0;
            
            &:last-of-type {
                margin-top: 20px;
            }
            
        }
        
    }
    
    .disporsal_date01__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed $blue02;
        
        dt,dd {
            font-size: 2rem;
            
            @include mq() {
                font-size: 1.8rem;
            }
            
        }
        
        dt {
            margin-right: 10px;
        }
        
        dd {
            span {
                color: $blue02;
                font-size: 2.4rem;
                @extend %bold;
                margin-right: 5px;
                
                @include mq() {
                    font-size: 2.2rem;
                }
                
            }
        }
    }
    
    .disporsal_box01 {
        margin-top: 45px;
        
        &:last-child {
            margin-top: 75px;
        }
        
        @include mq(950) {
            &:last-child {
                margin-top: 70px;
            }
        }
        
        @include mq() {
            margin-top: 23px;
            
            &:last-child {
                margin-top: 23px;
            }
            
        }
        
    }
    
    .disporsal_img_list01 {
        display: flex;
        margin-top: 35px;
        
        @include mq() {
            flex-wrap: wrap;
            margin-top: 18px;
        }
        
        li {
            margin-right: calc(8% / 4);
            position: relative;
            
            &:last-child {
                margin-right: 0;
            }
            
            @include mq() {
                width: 48%;
                margin-right: calc(8% / 2);
                margin-bottom: 35px;
                
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
            
            .disporsal_img_list01--title {
                max-width: 195px;
                width: 100%;
                padding: 5px;
                position: absolute;
                left: 50%;
                bottom: -30px;
                transform: translateX(-50%);
                font-size: 2rem;
                line-height: 1.3;
                text-align: center;
                color: #fff;
                @extend %bold;
                @include gradient_blue_deg;
                
                @include mq(950) {
                    max-width: 125px;
                    bottom: -25px;
                    font-size: 1.4rem;
                }
            }
            
        }
        
    }
    
    .disporsal_img_list02 {
        display: flex;
        margin-top: 35px;
        
        @include mq() {
            flex-wrap: wrap;
            margin-top: 18px;
        }
        
        li {
            margin-right: calc(8% / 4);
            position: relative;
            
            &:last-child {
                margin-right: 0;
            }
            
            @include mq() {
                width: 48%;
                margin-right: calc(8% / 2);
                margin-bottom: 10px;
                
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
            
            .disporsal_img_list02--title {
                max-width: 200px;
                width: 100%;
                padding: 5px;
                position: absolute;
                bottom: 20px;
                font-size: 2rem;
                line-height: 1.3;
                text-align: center;
                color: #fff;
                @extend %bold;
                @include gradient_blue_deg;
                
                @include mq(950) {
                    max-width: 125px;
                    bottom: 10px;
                    font-size: 1.4rem;
                }
                
            }
            
        }
    }
    
    .disporsal_line01 {
        background:linear-gradient(transparent 60%, #87b9db 60%);
    }
    
    .disporsal_images01 {
        margin-top: 50px;
        
        @include mq() {
            margin-top: 25px;
        }
    }
    
    .disporsal_text01 {
        @include mq() {
            margin-top: 5px;
        }
    }
    
    
    /* お問い合わせコンテンツ */
    .tell_links {
        .tell_links__ttl02 {
            font-family: $gothic_bold;
            font-size: 3.3rem;
            line-height: 1.3;
            position: relative;
            z-index: 1;
            
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            
            @include mq() {
                font-size: 2rem;
            }
            
            span {
                background: #fff;
                position: relative;
                display: inline-block;
                padding-right: 20px;
                
                @include mq() {
                    padding-right: 10px;
                }
                
            }
            
        }
        
        .tell_links__box01 {
            margin-top: 60px;
            
            @include mq() {
                margin-top: 30px;
            }
            
        }
        
        .tell_links__btn {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            a {
                position: relative;
                background: #fff;
                display: inline-block;
                text-align: center;
                font-size: 2.2rem;
                font-weight: bold;
                line-height: 1;
                color: #fff;
                border-radius: 100px;
                padding: 15px;
                min-width: 250px;
                border: 2px solid;
                transition: color .3s, background .3s, border-color .3s;
                
                @include mq() {
                    font-size: 1.8rem;
                    min-width: 200px;
                }
                
            }
            
            span {
                position: relative;
                padding-right: 25px;
                
                &::after {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    transition: border-color .3s;
                }
                
                @include mq() {
                    padding-right: 20px;
                    
                    &::after {
                        width: 8px;
                        height: 8px;
                        border-width: 2px;
                    }
                    
                }
                
            }
            
            
            &.tell_links__btn--form {
                a {
                    position: relative;
                    
                    @include hover {
                        color: $blue01;
                        
                        &::before {
                            opacity: 0;
                        }
                        
                        span {
                            &::after {
                                border-color: $blue01;
                            }
                        }
                        
                    }
                    
                    &::before {
                        content: "";
                        display: block;
                        width: calc(100% - 0px);
                        height: calc(100% - 0px);
                        @include gradient_blue_h;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 100px;
                        transition: opacity .3s;
                    }
                }
            }
            
        }
        
    }
    
}


/************************************************

	解体工事

*************************************************/
.p-service_disassembly {
    .b-head {
        background: url("/service/disassembly/images/head_disassembly.jpg") no-repeat center / cover;
    }
    
    .b-sttl01 {
        
        &::before {
            background: url("../images/bg_item_service_full.png") no-repeat center / contain;
        }
        
        .b-sttl01__inner {
            background: #fff;
        }
    }
    
    .s-sec02 {
        margin-top: 140px;
        
        @include mq() {
            margin-top: 70px;
        }
        
    }
    
    .s-sec02_box {
        background: rgba(255, 255, 255, .9);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .2);
        padding: 75px 100px;
        box-sizing: border-box;
        margin-top: 120px;
        
        @include mq() {
            padding: 20px;
            margin-top: 60px;
        }
        
        &:first-child {
            margin-top: 0;
        }
    }
    
    .s-sec02_box__inner {
        margin-top: 50px;
        padding-left: 25px;
        
        @include mq() {
            margin-top: 25px;
            padding-left: 0;
        }
        
    }
    
    .s-sec02_text01 {
        font-size: 2rem;
        
        @include mq() {
            font-size: 1.8rem;
        }
        
    }
    
    .service_disassembly_images01 {
        margin-top: 60px;
        
        @include mq() {
            margin-top: 30px;
        }
        
    }
    
    /* お問い合わせコンテンツ */
    .tell_links {
        .tell_links__ttl02 {
            font-family: $gothic_bold;
            font-size: 3.3rem;
            line-height: 1.3;
            position: relative;
            z-index: 1;
            
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            
            @include mq() {
                font-size: 2rem;
            }
            
            span {
                background: #fff;
                position: relative;
                display: inline-block;
                padding-right: 20px;
                
                @include mq() {
                    padding-right: 10px;
                }
                
            }
            
        }
        
        .tell_links__box01 {
            margin-top: 60px;
            
            @include mq() {
                margin-top: 30px;
            }
            
        }
        
        .tell_links__btn {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            a {
                position: relative;
                background: #fff;
                display: inline-block;
                text-align: center;
                font-size: 2.2rem;
                font-weight: bold;
                line-height: 1;
                color: #fff;
                border-radius: 100px;
                padding: 15px;
                min-width: 250px;
                border: 2px solid;
                transition: color .3s, background .3s, border-color .3s;
                
                @include mq() {
                    font-size: 1.8rem;
                    min-width: 200px;
                }
                
            }
            
            span {
                position: relative;
                padding-right: 25px;
                
                &::after {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    transition: border-color .3s;
                }
                
                @include mq() {
                    padding-right: 20px;
                    
                    &::after {
                        width: 8px;
                        height: 8px;
                        border-width: 2px;
                    }
                    
                }
                
            }
            
            
            &.tell_links__btn--form {
                a {
                    position: relative;
                    
                    @include hover {
                        color: $blue01;
                        
                        &::before {
                            opacity: 0;
                        }
                        
                        span {
                            &::after {
                                border-color: $blue01;
                            }
                        }
                        
                    }
                    
                    &::before {
                        content: "";
                        display: block;
                        width: calc(100% - 0px);
                        height: calc(100% - 0px);
                        @include gradient_blue_h;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 100px;
                        transition: opacity .3s;
                    }
                }
            }
            
        }
        
    }
    
    
}



/************************************************

	廃車買取フォーム

*************************************************/

.p-automobile_recycle_form {
    
    .b-head {
        background: url("/service/automobile-recycle/form/images/automobile-recycle_form.jpg") no-repeat center / cover;
    }
    
    .b-contents__inner {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
    }
    
    .b-sttl01 {
        
        &::before {
            left: -12%;
        }
    }
    
    .tell_links {
        margin-top: 80px;
        background: #fff;
        border: 1px solid #b3b3b3;
        padding: 60px 40px 50px;
        color: #666;
        
        @include mq() {
            margin-top: 60px;
            padding: 30px 20px 25px;
        }
        
        .tell_links__inner {
            max-width: 1020px;
            margin: 0 auto;
        }
        
        .tell_links__ttl {
            font-size: 3.3rem;
            line-height: 1.3;
            @extend %bold;
            position: relative;
            z-index: 1;
            
            @include mq() {
                font-size: 2rem;
            }
            
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: #666;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    
                }
            }
        }
        
        .tell_links__ico {
            background: #fff;
            position: relative;
            display: inline-block;
            padding-left: 80px;
            padding-right: 20px;
            
            @include mq() {
                padding-left: 40px;
                padding-right: 10px;
            }
            
            &::before {
                content: '';
                display: block;
                background: #666 url('../images/ico_tell.svg') no-repeat center / 28px 36px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
                @include mq() {
                    width: 30px;
                    height: 30px;
                    background-size: 14px 18px;
                }
            }
        }
        
        .tell_links__list {
            margin-top: 40px;
            
            @include mq() {
                margin-top: 20px;
            }
            
            li {
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }
        }
        
        .tell_links__label {
            font-size: 2.3rem;
            line-height: 1.5;
            @extend %bold;
            width: 480px;
            flex-shrink: 0;
            margin-right: 100px;
            
            @include mq() {
                font-size: 1.6rem;
                margin-right: 0;
                flex-shrink: inherit;
            }
        }
        
        .tell_links__number {
            font-size: 2.5rem;
            line-height: 1.3;
            
            @include mq() {
                font-size: 1.8rem;
            }
        }
    }
    
    .b-sttl01 {
        margin-top: 80px;
        
        @include mq() {
            margin-top: 40px;
        }
    }
    
    .b-sttl04 {
        margin-top: 30px;
        margin-bottom: 20px;
    }
    
    .footer {
        margin-top: 75px;
    }
}