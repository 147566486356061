.header {
    background: #fff;
    position: relative;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    
    .haeder__inner {
        padding: 15px 50px 0;
        display: -webkit-flex;
        display: flex;
        
        @include mq(1215) {
            align-items: center;
            padding: 15px 30px;
        }
        
        @include mq() {
            padding: 10px 15px;
        }
    }
    
    .header__logo {
        margin-right: 20px;
        width: 250px;
        
        
        @include mq() {
            width: 150px;
        }
        
        a {
            display: inline-block;
            @include hover_op;
        }
    }
    
    .header__btns {
        margin-left: auto;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        
        .header__btns__btn {
            margin-right: 20px;
            
            @include mq(1215) {
                display: none;
            }
            
            &:nth-child(3) {
                margin-right: 0;
            }
            
            a {
                display: block;
                @extend %bold;
                list-style: 1.3em;
                font-size: 1.6rem;
                background: #666;
                color: #fff;
                border-radius: 100px;
                padding: 7px 5px;
                min-width: 190px;
                text-align: center;
                letter-spacing: .05em;
                border: 1px solid;
                transition: color .3s, background .3s, border-color .3s;
            }
            
            &.header__btns__btn--blue {
                
                a {
                    //@include gradient_blue_h;
                    position: relative;
                    background: #fff;
                    
                    @include hover {
                        color: $blue01;
                        //background: #fff;
                        
                        &::before {
                            opacity: 0;
                        }
                    }
                    
                    &::before {
                        content: '';
                        display: block;
                        width: calc(100% - 0px);
                        height: calc(100% - 0px);
                        @include gradient_blue_h;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        border-radius: 100px;
                        transition: opacity .3s;
                        
                        
                    }
                }
            }
            
            &.header__btns__btn--red {
                
                a {
                    background: $red01;
                    
                    @include hover {
                        color: $red01;
                        background: #fff;
                    }
                }
            }
			
			&.header__btns__btn--green {
                
                a {
                    //@include gradient_blue_h;
                    position: relative;
                    background: #fff;
					                    
                    @include hover {
                        color: $blue01;
                        //background: #fff;
                        
                        &::before {
                            opacity: 0;
                        }
                    }
                    
                    &::before {
                        content: '';
                        display: block;
                        width: calc(100% - 0px);
                        height: calc(100% - 0px);
                        @include gradient01_h;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        border-radius: 100px;
                        transition: opacity .3s;
                    }
					
					span{
						position: relative;
    					display: inline-block;
					}
                }
            }
        }
        
        .header__btns__menu {
            display: none;
            
            @include mq(1215) {
                display: block;
            }
            
            button {
                display: block;
                width: 45px;
                height: 30px;
                position: relative;
                
                @include mq() {
                    width: 30px;
                    height: 25px;
                }
                
                span {
                    width: 100%;
                    height: 2px;
                    @include gradient_blue_h;
                    position: absolute;
                    left: 0;
                    
                    &:nth-child(1) {
                        top: 0;
                        transition: transform .3s, top .3s;
                    }
                    
                    &:nth-child(2) {
                        top: 50%;
                        transform: translateY(-50%);
                        transition: width .3s, opacity .3s;
                    }
                    
                    &:nth-child(3) {
                        bottom: 0;
                        transition: transform .3s, bottom .3s;
                    }
                }
                
                &.is-open {
                    
                    span {
                        width: 100%;
                        height: 2px;
                        @include gradient_blue_h;
                        position: absolute;
                        left: 0;

                        &:nth-child(1) {
                            top: 50%;
                            transform: translateY(-50%) rotate(45deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                            width: 0;
                        }

                        &:nth-child(3) {
                            transform: translateY(50%) rotate(-45deg);
                            bottom: 50%;
                        }
                    }
                }
            }
        }
        
        &.header__btns--sp {
            display: none;
            margin-top: 30px;
            
            @include mq(1215) {
                display: block;
            }
            
            .header__btns__btn {
                
                margin-top: 20px;
                
                @include mq(1215) {
                    display: block;
                }
                
                &.header__btns__btn--blue,
                &.header__btns__btn--green {
                    margin-right: 0;
                }
                
                a {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.gnav {
    margin-top: 15px;
    padding: 0 50px 15px;
    
    @include mq(1215) {
        position: absolute;
        top: 100%;
        left: 100%;
        background: mix($main, #fff, 20%);
        margin-top: 0;
        padding: 40px;
        min-height: 100vh;
        transform: translateX(0);
        transition: transform .3s;
    }
    
    @include mq() {
        padding: 20px;
    }
    
    &.is-open {
        transform: translateX(-100%);
    }
    
    ul {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        @include mq(1215) {
            display: block;
        }
        
        li {
            padding-right: 37px;
            margin-right: 20px;
            position: relative;
            
            @include mq(1355) {
                padding-right: 27px;
                margin-right: 10px;
            }
            

            @include mq(1215) {
                margin-right: 0;
                padding-right: 0;
                //text-align: center;
            }
            
            + li {
                
                @include mq(1215) {
                    margin-top: 15px;
                }
            }
            
            &::after {
                content: '';
                display: block;
                background: url('../images/ico_gnav_sepalate.svg') no-repeat center / 17px 29px;
                width: 17px;
                height: 29px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) translateY(-1px);
                
                @include mq(1215) {
                    display: none;
                }
            }
            
            &:last-child {
                padding-right: 0;
                margin-right: 10px;
                
                &::after {
                    display: none;
                }
            }
            
            a {
                font-size: 1.6rem;
                line-height: 1.3;
                @extend %bold;
                position: relative;
                transition: color .3s;
                
                @include hover {
                    color: $main;
                    
                    &::after {
                        width: 100%;
                    }
                }
                
                @include mq(1525) {
                    //font-size: 1.8rem;
                }
                
                @include mq(1335) {
                    //font-size: 1.6rem;
                }
                
                @include mq(1120) {
                    //color: $main;
                }
                
                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    background: $main;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: width .2s;
                    
                    @include mq(1215) {
                        left: 0;
                        transform: none;
                    }
                }
            }
        }
    }
}